import React, { Component } from 'react';
import { observer } from 'mobx-react';
import NavigationSide from '../../../components/navigation-side/NavigationSide';
import NavigationTop from '../../../components/navigation-top/NavigationTop';
import LoaderComponent from '../../../components/loader/Loader.component';
import juiceStore from '../../../../stores/juice.store';
import styled from 'styled-components';
import UserItem from './UserItem';

export const Styles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;;

  @media (max-width: 900px) {
    display: block;
  }
`;

@observer
class JuiceUsersView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }

    async componentDidMount() {
        document.title = 'Users - juice';
        await juiceStore.getUsers();
        this.setState({ loader: false });
    }

    render() {
        const users = juiceStore.users;
        const total = users.length;

        return (
            <div className="main-wrapper">
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    { this.state.loader && (<LoaderComponent/>) }

                    { !this.state.loader && (
                        <>
                            <h1>Users</h1>
                            <h3>Total: { total }</h3>
                            <Styles>
                                { users.map(user => {
                                    return (<UserItem key={ user._id } user={ user }/>);
                                }) }
                            </Styles>
                        </>
                    ) }
                </main>
            </div>

        );
    }
}

export default JuiceUsersView;