import React, { Component } from 'react';
import { observer } from 'mobx-react';
import messagesStore from '../../../../stores/messages.store';
import NavigationSide from '../../../components/navigation-side/NavigationSide';
import NavigationTop from '../../../components/navigation-top/NavigationTop';
import LoaderComponent from '../../../components/loader/Loader.component';
import { Styles } from './styled';

@observer
class MessagesEditView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            name: '',
            body: '',
            promoLink: '',
            promoText: '',
            imageUrl: ''
        };
    }

    handleInputChange = (e, type) => {
        this.setState({
            [type]: e.target.value
        });
    };

    handleSave = async e => {
        e.preventDefault();
        this.setState({ loader: true });
        const { name, body, promoText, promoLink, imageUrl } = this.state;
        const { id } = this.props.match.params;
        const payload = {
            body: body,
            name: name,
            promoLink: promoLink,
            promoText: promoText,
            imageUrl
        };
        if (id) {
            await messagesStore.updateMessage(id, payload);
        } else {
            await messagesStore.createMessage(payload);
        }
        this.props.history.push('/bots/messages');
    };

    async componentDidMount() {
        document.title = 'Message edit - shemesh.info';
        const { id } = this.props.match.params;

        if (id) {
            const message = await messagesStore.getMessage(id);
            if (message) {
                const plink = message.promoLink ? message.promoLink : '';
                const ptext = message.promoText ? message.promoText : '';
                this.setState(
                    { name: message.name, body: message.body, loader: false, promoLink: plink, promoText: ptext, imageUrl: message.imageUrl || '' });
            }
        } else {
            this.setState({ loader: false });
        }
    }

    render() {
        const { loader, name, body, promoLink, promoText, imageUrl } = this.state;
        const { id } = this.props.match.params;

        return (
            <div className="main-wrapper">
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    { loader && (<LoaderComponent/>) }

                    { !loader && (
                        <Styles className="message-edit">
                            <h1>{id ? 'Edit Message' : 'Add New Message'}</h1>

                            <div className="form-item">
                                <label>
                                    Name
                                </label>
                                <input
                                    onChange={(e) => this.handleInputChange(e,
                                        'name'
                                    )}
                                    value={name}
                                    type="text"/>
                            </div>

                            <div className="form-item">
                                <label>
                                    Message Body
                                </label>
                                <textarea
                                    onChange={(e) => this.handleInputChange(e,
                                        'body'
                                    )}
                                    value={body}
                                />
                            </div>

                            <div className="form-item">
                                <label>
                                    promoText
                                </label>
                                <input
                                    onChange={(e) => this.handleInputChange(e,
                                        'promoText'
                                    )}
                                    value={promoText}
                                    type="text"/>
                            </div>

                            <div className="form-item">
                                <label>
                                    promoLink
                                </label>
                                <input
                                    onChange={(e) => this.handleInputChange(e,
                                        'promoLink'
                                    )}
                                    value={promoLink}
                                    type="text"/>
                            </div>
                            <div className="form-item">
                                <label>
                                    image url
                                </label>
                                <input
                                    onChange={(e) => this.handleInputChange(e,
                                        'imageUrl'
                                    )}
                                    value={imageUrl}
                                    type="text"/>
                            </div>

                            <div className="form-item">
                                <button
                                    onClick={this.handleSave}
                                    className="button suc">
                                    Save
                                </button>
                            </div>
                        </Styles>
                    )}

                </main>
            </div>
        );
    }
}

export default MessagesEditView;