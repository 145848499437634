import React, { useState } from 'react';
import styled from 'styled-components';
import syagentStore from '../../../../stores/syagent.store';

export const Styles = styled.div`
  width: 400px;
  margin: 30px 0;
`;

export const StylesItem = styled.div`

`;

export const StylesButtonsList = styled.div`
  display: grid;
  grid-gap: 20px;
`;

const UserEdit = ({ user }) => {
    const [sub, setSub] = useState(user.subscription);

    return (
        <Styles>
            <StylesItem>
                <label>Subscription</label>
                <input type="number" value={ sub }
                       onChange={ (e) => setSub(e.target.value) }/>
            </StylesItem>
            <StylesButtonsList>
                <button onClick={ () => {
                    syagentStore.editUser(user._id,
                        { subscription: Number(sub) }
                    );
                } } className="suc">
                    Save
                </button>
                <button onClick={ () => {
                    syagentStore.upgradeSubscription(user._id,
                        { subscription: 3 }
                    );
                } } className="suc">
                    Upgrade to 3
                </button>
                <button onClick={ () => {
                    syagentStore.upgradeSubscription(user._id,
                        { subscription: 4 }
                    );
                } } className="suc">
                    Upgrade to 4
                </button>

                <button onClick={ () => {
                    syagentStore.upgradeSubscription(user._id,
                        { subscription: 4 }
                    );
                } } className="suc">
                    Upgrade to 5
                </button>
            </StylesButtonsList>

        </Styles>
    );
};

export default UserEdit;