import React from 'react';

const LoaderComponent = (props) => {
    return (
        <div id='loader'>
            <div className="seconds">{props.seconds}</div>
            <div className="lds-dual-ring"/>
        </div>
    );
};

export default LoaderComponent;