import React, {PureComponent} from 'react';
import botsStore from "../../../stores/bots.store";
import NavigationSide from "../../components/navigation-side/NavigationSide";
import NavigationTop from "../../components/navigation-top/NavigationTop";
import LoaderComponent from "../../components/loader/Loader.component";
import utilsService from "../../../services/utils.service";
import BotStatsHighChart from "./components/BotStatsHighChart";
import moment from "moment";

class BotStatsV2View extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            range: 14
        };
    }

    async componentDidMount() {
        document.title = "Job Statistics - shemesh.info";

        const keys = Object.keys(botsStore.jobStats2);
        if (keys.length === 0) {
            const body = {
                timeStamp: Date.now() - (86400000 * 14)
            }
            await botsStore.getJobStatsV2(body);
        }
        this.setState({loader: false});
    }

    handleSelectChange = async e => {
        const range = e.target.value;
        this.setState({loader: true, range: range});
        const body = {
            timeStamp: Date.now() - (86400000 * range)
        }
        await botsStore.getJobStatsV2(body);
        this.setState({loader: false});
    };

    convertToChartData = stat => {
        const tempData = {categories: [], series: []};

        const followers = {name: 'Followers', data: []};
        const following = {name: 'Following', data: []};

        for (let i = stat.length - 1; i >= 0; i--) {
            const item = stat[i];

            tempData.categories.push(
                moment(item.createdAt).format('DD/MM')
            );

            // followers
            followers.data.push({
                y: item.followers
            });

            if (item.following) {
                // following
                following.data.push({
                    y: item.following
                });
            }
        }

        tempData.series.push(followers);
        if (following.data.length > 0) {
            tempData.series.push(following);
        }
        return tempData;
    }

    calculateDelta(followers) {
        let delta = 0;
        let classes = 'delta';
        let arrow = '';
        const len = followers.length;

        if (len > 0) {
            const firstEl = followers[0];
            const lastEl = followers[len - 1];
            delta = firstEl.followers - lastEl.followers;
        }

        if (delta > 0) {
            arrow = 'up';
            classes += ' positive';
        }

        if (delta < 0) {
            arrow = 'down';
            classes += ' negative';
        }

        return (
            <div className="stats-delta">
                <span className={classes}>{delta}</span>
                {arrow && (
                    <i className={`fas fa-angle-double-${arrow}`}/>
                )}
            </div>
        );
    }

    render() {
        const statsObj = botsStore.jobStats2;
        const keys = Object.keys(botsStore.jobStats2);

        return (
            <div className='main-wrapper'>
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    {this.state.loader && (<LoaderComponent/>)}

                    {!this.state.loader && (
                        <React.Fragment>
                            <h1>Statistics</h1>

                            <div className="time-select form">
                                <div className="form-item">
                                    <select
                                        value={this.state.range}
                                        onChange={(e) => this.handleSelectChange(e)}
                                    >
                                        <option value={7}>Week</option>
                                        <option value={14}>2 Weeks</option>
                                        <option value={30}>Month</option>
                                        <option value={90}>3 Month</option>
                                    </select>
                                </div>

                            </div>

                            <div className="job-edit-wrapper bots-stats">
                                {keys.map(key => {
                                    const stat = statsObj[key];
                                    const statItem = stat[0];
                                    return (
                                        <section className="panel-x" key={statItem._id}>
                                            <div className="panel-header">
                                                <h3><span> <i
                                                    className={`fab fa-${utilsService.getIcon(statItem.type)}`}/></span>
                                                    {statItem.name}</h3>

                                                {this.calculateDelta(stat)}

                                                <div
                                                    className="followers">(Followers: {statItem.followers})
                                                </div>
                                            </div>
                                            <div className="panel-body">
                                                <BotStatsHighChart
                                                    data={this.convertToChartData(stat)}/>
                                            </div>
                                        </section>);
                                })}

                            </div>
                        </React.Fragment>
                    )}
                </main>
            </div>
        );
    }
}

export default BotStatsV2View;
