import React from 'react';
import styled from 'styled-components';
import SentAlertItemMini from './SentAlertItemMini';

export const Styles = styled.div`
  display: grid;
  grid-gap: 20px;
  width: 100%;
  word-break: break-all;
`;

const SentAlertsList = ({ list }) => {
    return (
        <div>
            <h2>Total: { list.length }</h2>
            <Styles>
                { list.map((item, idx) => {
                    return (
                        <SentAlertItemMini key={ idx + 'adawd' }
                                           item={ item }/>);
                }) }
            </Styles>
        </div>
    );
};

export default SentAlertsList;