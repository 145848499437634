import React, { Component } from "react";
import { observer } from "mobx-react";
import NavigationSide from "../../../components/navigation-side/NavigationSide";
import NavigationTop from "../../../components/navigation-top/NavigationTop";
import LoaderComponent from "../../../components/loader/Loader.component";
import { Styles } from "../messages/styled";
import messagesStore from "../../../../stores/messages.store";


@observer
class SendMessageView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            body: "",
            channelId: "",
            imageUrl: "",
            videoUrl: "",
            promoText: "",
            promoLink: ""
        };
    }

    async componentDidMount() {
        document.title = "Send Messages - shemesh.info";
        this.setState({ loader: false });
    }

    handleInputChange = (e, type) => {
        this.setState({
            [type]: e.target.value
        });
    };
    handleSave = async e => {
        e.preventDefault();
        this.setState({ loader: true });

        const { channelId, body, promoLink, promoText, imageUrl, videoUrl } = this.state;
        const payload = {
            channelId,
            body,
            promoLink,
            promoText,
            imageUrl,
            videoUrl
        };

        await messagesStore.sendMessage(payload);
        this.setState({ loader: false, body: "", channelId: "", promoLink: "", promoText: "", imageUrl: "", videoUrl: "" });
    }

    render() {
        const { loader, channelId, body, promoLink, promoText, imageUrl, videoUrl } = this.state;

        return (
            <div className="main-wrapper">
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    { loader && (<LoaderComponent/>) }

                    { !loader && (
                        <Styles className="send-message">
                            <h1>Send message</h1>

                            <div className="form-item">
                                <label>
                                    Telegram Id
                                </label>
                                <input
                                    onChange={ (e) => this.handleInputChange(e,
                                        "channelId"
                                    ) }
                                    value={ channelId }
                                    type="text"/>
                            </div>

                            <div className="form-item">
                                <label>
                                    Message Body
                                </label>
                                <textarea
                                    onChange={ (e) => this.handleInputChange(e,
                                        'body'
                                    ) }
                                    value={ body }
                                />
                            </div>
                            <div className="form-item">
                                <label>
                                    promoText
                                </label>
                                <input
                                    onChange={ (e) => this.handleInputChange(e,
                                        'promoText'
                                    ) }
                                    value={ promoText }
                                    type="text"/>
                            </div>

                            <div className="form-item">
                                <label>
                                    promoLink
                                </label>
                                <input
                                    onChange={ (e) => this.handleInputChange(e,
                                        'promoLink'
                                    ) }
                                    value={ promoLink }
                                    type="text"/>
                            </div>
                            <div className="form-item">
                                <label>
                                    image url
                                </label>
                                <input
                                    onChange={ (e) => this.handleInputChange(e,
                                        'imageUrl'
                                    ) }
                                    value={ imageUrl }
                                    type="text"/>
                            </div>
                            <div className="form-item">
                                <label>
                                    video url
                                </label>
                                <input
                                    onChange={ (e) => this.handleInputChange(e,
                                        'videoUrl'
                                    ) }
                                    value={ videoUrl }
                                    type="text"/>
                            </div>

                            <div className="form-item">
                                <button
                                    onClick={ this.handleSave }
                                    className="button suc">
                                    Send
                                </button>
                            </div>
                        </Styles>
                    ) }

                </main>
            </div>
        );
    }
}

export default SendMessageView;
