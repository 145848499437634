import React, { Component } from 'react';
import { observer } from 'mobx-react';
import syagentStore from '../../../../stores/syagent.store';
import NavigationSide from '../../../components/navigation-side/NavigationSide';
import NavigationTop from '../../../components/navigation-top/NavigationTop';
import LoaderComponent from '../../../components/loader/Loader.component';
import UsersList from './UsersList';

@observer
class UsersView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }

    async componentDidMount() {
        document.title = 'Users - syagent';
        await syagentStore.getUsers();
        this.setState({ loader: false });
    }

    render() {
        const users = syagentStore.users;
        const total = users.length;

        return (
            <div className="main-wrapper">
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    { this.state.loader && (<LoaderComponent/>) }

                    { !this.state.loader && (
                        <>
                            <h1>Users</h1>
                            <h3>Total: { total }</h3>
                            <UsersList
                                list={ users.slice() }
                            />
                        </>
                    ) }
                </main>
            </div>

        );
    }
}

export default UsersView;