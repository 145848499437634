import React from 'react';
import DataGrid, {Pager, Paging, SearchPanel, Column} from 'devextreme-react/data-grid';
import utilsService from "../../../../../services/utils.service";

const DataTable = (props) => {

    const sizeFormatter = (data) => utilsService.formatBytes(data.value);

    return (
        <div className="data-table">
            <DataGrid
                dataSource={props.data}
                columns={['ns', 'count', 'nindexes', 'avgObjSize', 'size', 'storageSize', 'totalIndexSize']}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
            >

                <Column
                    dataField={'ns'}
                />
                <Column
                    dataField={'count'}
                />
                <Column
                    dataField={'nindexes'}
                />
                <Column
                    dataField={'avgObjSize'}
                    cellRender={sizeFormatter}
                />

                <Column
                    dataField={'size'}
                    cellRender={sizeFormatter}
                />

                <Column
                    dataField={'storageSize'}
                    cellRender={sizeFormatter}
                />

                <Column
                    dataField={'totalIndexSize'}
                    cellRender={sizeFormatter}
                />

                <SearchPanel visible={true}
                             width={240}
                             placeholder={'Search...'}/>
                <Paging pageSize={20}/>
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}/>
            </DataGrid>
        </div>
    );
};

export default DataTable;