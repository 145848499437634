import React from 'react';

const TopPanel = () => {
    return (
        <div className='dashboard-top-panel'>
            <div className="panel">
                <p>Jobs</p>
                <p className="body">
                    150
                </p>
            </div>
            <div className="panel">
                <p>Jobs</p>
                <p className="body">
                    150
                </p>
            </div>
            <div className="panel">
                <p>Jobs</p>
                <p className="body">
                    150
                </p>
            </div>
        </div>
    );
};

export default TopPanel;