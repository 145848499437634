import {action, observable} from 'mobx';
import fetchService from '../services/fetch.service';

class CatTypesStore {
    @observable categories = [];
    @observable types = [];

    @action.bound
    async getCats() {
        const bots = await fetchService.authenticatedGet('/huj/tc/types-categories');
        this.parseToLists(bots.data);
    }

    parseToLists(data) {

        const tempTypes = [];
        const tempCats = [];

        data.forEach(item => {
            if (item.type === 'type') {
                tempTypes.push(item);
            } else {
                tempCats.push(item);
            }
        });

        this.types = tempTypes;
        this.categories = tempCats;
    }

    @action.bound
    async createType(type) {
        const bots = await fetchService.authenticatedPost(`/huj/jobs/type/${type}`);
        this.parseToLists(bots.data);
    }

    @action.bound
    async createCategory(category) {
        const bots = await fetchService.authenticatedPost(`/huj/jobs/category/${category}`);
        this.parseToLists(bots.data);
    }

    @action.bound
    async deleteTypeCat(typeCat) {
        const bots = await fetchService.authenticatedDelete(`/huj/jobs/category/${typeCat}`);
        this.parseToLists(bots.data);
    }

    @action.bound
    async update(type, body) {
        const data = await fetchService.authenticatedPut(`/huj/jobs/category/${type}`, body);
        this.parseToLists(data.data);
    }

}

const catTypesStore = new CatTypesStore();

export default catTypesStore;
