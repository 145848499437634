import React from 'react';
import firebase from 'firebase/app';
import userStore from '../../../stores/user.store';

const NavigationTop = () => {
    const logOut = () => {
        firebase.auth().signOut();
        userStore.logOut();
    };


    return (
        <div className='nav-top'>
            <div className="nav-user">
                {userStore.user}
            </div>
            <div className="logout">
                <button className='dark' onClick={() => logOut()}>Log Out</button>
            </div>
        </div>
    );
};

export default NavigationTop;