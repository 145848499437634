import React from 'react';
import utilsService from "../../../../../services/utils.service";

const TopDbPanel = (props) => {
    return (
        <div className="db-panel">
            <div>
                Collections: {props.databases.length}
            </div>

            <div>
                Total Size : {utilsService.formatBytes(props.databases.size)}
            </div>
            <div>
                Total Storage Size : {utilsService.formatBytes(props.databases.storageSize)}
            </div>

            <div>
                Total Index Size : {utilsService.formatBytes(props.databases.totalIndexSize)}
            </div>
        </div>
    );
};

export default TopDbPanel;