import React from 'react';
import {
    MessagesEditSectionItemStyled,
    MessagesEditSectionStyled, MessagesEditSectionWrapStyled
} from './styled';

const MessagesEditJob = ({ messages, messagesList, setMessages }) => {

    return (
        <MessagesEditSectionStyled
            className="form-item"
        >
            <label>
                Messages
            </label>

            <MessagesEditSectionWrapStyled>
                { messagesList.map((item, idx) => {
                    const isActive = messages.find(
                        dd => dd.messageId === item._id);

                    return (
                        <MessagesEditSectionItemStyled
                            className={ isActive ? 'active' : '' }
                            key={ item._id + idx + 'pzer' }
                            onClick={ () => {
                                setMessages(item);
                            } }
                        >
                            { item.name }
                        </MessagesEditSectionItemStyled>
                    );
                })
                }
            </MessagesEditSectionWrapStyled>
        </MessagesEditSectionStyled>
    );
};

export default MessagesEditJob;