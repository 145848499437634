import React from 'react';
import { MessagesListStyled } from './styled';
import MessageItem from './MessageItem';

const MessagesList = ({ list }) => {
    return (
        <MessagesListStyled>
            { list.map(item => {
                return (
                    <MessageItem
                        item={ item }
                        key={ item._id }
                    />
                );
            }) }
        </MessagesListStyled>
    );
};

export default MessagesList;