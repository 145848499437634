import {action, observable} from "mobx";
import appSettings from '../constants/settings.constants';

class UserStore {
    @observable user = null;
    @observable error = null;
    @observable logged = false;
    @observable userToken = null;

    @action.bound
    async authenticateUser(googleJwt) {
        this.userToken = googleJwt;
        const request = new Request(`${appSettings.apiUrl}/auth/`, {
            method: 'POST',
            body: JSON.stringify({token: googleJwt}),
            headers: {"content-type": "application/json"}
        });

        const authResult = await fetch(request);

        if (authResult.status === 403) {
            this.user = null;
        } else {
            const json = await authResult.json();
            this.user = json.data;
            this.user.displayName = this.user.firstName + ' ' + this.user.lastName;
            this.logged = true;
        }

    }

    @action.bound
    async getCurrentUser(googleJwt, user) {
        this.userToken = googleJwt;
        this.user = user.email;
        this.logged = true;
        return true;

    }

    @action.bound
    logOut() {
        this.user = null;
        this.logged = false;
        this.userToken = null;
        window.location.href = '/';
    }

    getUser() {
        const userStringified = localStorage.getItem('user');
        let userJson = null;
        if (!userStringified || userStringified === '{}') {
            return;
        } else {
            userJson = JSON.parse(userStringified);
        }

        try {
            if (userJson.name) {
                this.user = userJson;
            }
        }
        catch (err) {
            console.log('error parsing user data from local storage');
        }
    }
}

const userStore = new UserStore();

export default userStore;
