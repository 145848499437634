import React, {Component} from 'react';
import CommonWrapperComponent from "../../../components/CommonWrapper.component";
import monitoringStore from "../../../../stores/monitoring.store";

import TopDbPanel from "./components/TopDbPanel";
import DataTable from "./components/DataTable";
import DbBarChart from "./components/DbBarChart";

class DatabasesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        }
    }

    generateBarChartData = (data) => {
        return data.map(item => {
            return ({
                collection: item.ns,
                totalSize: item.storageSize,
                size: item.size,
                totalIndexSize: item.totalIndexSize
            })
        });
    };

    calcSize = (dbs, key) => {
        let num = 0;
        dbs.forEach(item => {
            num += item[key];
        });

        return num;
    };

    async componentDidMount() {
        document.title = "Databases - shemesh.info";
        if (monitoringStore.databases.length === 0) {
            await monitoringStore.getDatabases();
            this.setState({loader: false});
        } else {
            this.setState({loader: false});
        }
    }

    render() {
        const databases = monitoringStore.databases;
        const topPanelData = {
            length: databases.length,
            size: this.calcSize(databases, 'size'),
            storageSize: this.calcSize(databases, 'storageSize'),
            totalIndexSize: this.calcSize(databases, 'totalIndexSize')
        };
        return (
            <React.Fragment>
                <CommonWrapperComponent
                    title="Databases"
                    loader={this.state.loader}
                >
                    {databases.length > 0 && (
                        <section className='databases'>
                            <TopDbPanel
                                databases={topPanelData}
                            />

                            <h3>Sizes</h3>

                            <DbBarChart
                                data={this.generateBarChartData(databases)}
                            />

                            <h3>Collections</h3>
                            <DataTable
                                data={databases}
                            />

                        </section>
                    )}

                </CommonWrapperComponent>
            </React.Fragment>
        );
    }
}

export default DatabasesView;