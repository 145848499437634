import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Styles = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  position: relative;
  background: #fff;
  border: 1px solid #e6e9ed;
  -webkit-column-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  column-break-inside: avoid;
  opacity: 1;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
`;

export const StylesServerDt = styled.div`
  span {
    font-weight: bold;
  }
`;

const ServerItemMini = ({ item }) => {

    const metric = item && Array.isArray(item.metrics) && item.metrics.length >
    0 ? item.metrics[0] : null;

    const user = item && Array.isArray(item.users) && item.users.length >
    0 ? item.users[0] : null;

    return (
        <Styles>
            <StylesServerDt>
                Name: <span>{ item.name }</span>
            </StylesServerDt>

            { item.ipData && (
                <>
                    <StylesServerDt>
                        Country: <span>{ item.ipData.countryname
                        ? item.ipData.countryname
                        : 'NA' }</span>
                    </StylesServerDt>
                    <StylesServerDt>
                        Hoster: <span>{ item.ipData.asn
                        ? item.ipData.asn.name
                        : 'NA' }</span>
                    </StylesServerDt>
                </>
            ) }

            { metric && (
                <>
                    <StylesServerDt>
                        CPU name: <span>{ metric.cpu_name }</span>
                    </StylesServerDt>
                    <StylesServerDt>
                        OS name: <span>{ metric.os_name }</span>
                    </StylesServerDt>
                </>
            ) }

            { user && (
                <StylesServerDt>
                    <Link
                        to={ `/syagent/users/${ user._id }` }>
                        More Info
                    </Link>
                </StylesServerDt>
            ) }
        </Styles>
    );
};

export default ServerItemMini;