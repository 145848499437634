import React, { Component } from 'react';
import syagentStore from '../../../../stores/syagent.store';
import NavigationSide from '../../../components/navigation-side/NavigationSide';
import NavigationTop from '../../../components/navigation-top/NavigationTop';
import LoaderComponent from '../../../components/loader/Loader.component';
import SentAlertsList from './SentAlertsList';

class SentAlertsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }

    async componentDidMount() {
        document.title = 'Alerts - syagent';
        await syagentStore.getSentAlerts();
        this.setState({ loader: false });
    }

    render() {
        const sentAlerts = syagentStore.sentAlerts;
        const total = sentAlerts.length;

        return (
            <div className="main-wrapper">
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    { this.state.loader && (<LoaderComponent/>) }

                    { !this.state.loader && (
                        <>
                            <h1>Alerts</h1>
                            <h3>Total: { total }</h3>
                            <SentAlertsList
                                list={ sentAlerts.slice() }
                            />
                        </>
                    ) }
                </main>
            </div>

        );
    }
}

export default SentAlertsView;