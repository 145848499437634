import React, {Component} from 'react';
import NavigationSide from "../../components/navigation-side/NavigationSide";
import NavigationTop from "../../components/navigation-top/NavigationTop";
import LoaderComponent from "../../components/loader/Loader.component";
import botsStore from "../../../stores/bots.store";
import utilsService from "../../../services/utils.service";

import moment from 'moment';
import BotStatsHighChart from "./components/BotStatsHighChart";


class BotsStatsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            range: 14,
            now: Date.now()
        };
    }

    connvertToChartData = (followers, following) => {
        const tempData = {categories: [], series: []};
        const followersDataObj = this.makeChartObj(followers, 'Followers');
        tempData.categories = followersDataObj.categories;
        tempData.series.push(followersDataObj.series);

        if (following) {
            const followingDataObj = this.makeChartObj(following, 'Following');
            tempData.series.push(followingDataObj.series);
        }

        return tempData;
    };

    makeChartObj = (data, name) => {
        const temp = {categories: [], series: {name: name, data: []}};
        const dateFrom = this.state.now - (this.state.range * 86400000);

        for (let i = 0; i < data.length; i++) {
            if (data[i].date >= dateFrom) {
                temp.series.data.push({
                    y: data[i].value
                });
                temp.categories.push(
                    moment(data[i].date).format('DD/MM')
                );
            }
        }

        return temp;

    };

    handleSelectChange = async (e) => {
        await this.setState({
            range: e.target.value
        });
    };


    async componentDidMount() {
        document.title = "Job Statistics - shemesh.info";
        if (botsStore.jobStats.length === 0) {
            await botsStore.getJobStats();
        }
        this.setState({loader: false});
    }

    calculateDelta(followers) {
        let delta = 0;
        let classes = 'delta';
        const len = followers.length;

        if (len > 0) {
            const firstEl = followers[0];
            const lastEl = followers[len - 1];
            delta = lastEl.value - firstEl.value;
        }

        if (delta > 0) {
            classes += ' positive';
        }

        if (delta < 0) {
            classes += ' negative';
        }

        return (<span className={classes}>{delta}</span>)
    }

    render() {
        const stats = botsStore.jobStats;

        return (
            <div className='main-wrapper'>
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    {this.state.loader && (<LoaderComponent/>)}

                    {!this.state.loader && (
                        <React.Fragment>
                            <h1>Statistics</h1>

                            <div className="time-select form">
                                <div className="form-item">
                                    <select
                                        value={this.state.range}
                                        onChange={(e) => this.handleSelectChange(e)}
                                    >
                                        <option value={7}>Week</option>
                                        <option value={14}>2 Weeks</option>
                                        <option value={30}>Month</option>
                                        <option value={90}>3 Month</option>
                                    </select>
                                </div>

                            </div>

                            <div className="job-edit-wrapper bots-stats">
                                {stats.map(stat => {
                                    return (
                                        <section className="panel-x" key={stat._id}>
                                            <div className="panel-header">
                                                <h3>
                                                    <span>
                                                        <i className={`fab fa-${utilsService.getIcon(stat.type)}`}/>
                                                    </span>
                                                    {stat.name}
                                                </h3>
                                                <div className="stats-delta">
                                                    {this.calculateDelta(stat.followers)}
                                                </div>
                                                <div className="followers">
                                                    (Followers: {stat.followers[stat.followers.length -
                                                1].value})
                                                </div>
                                            </div>
                                            <div className="panel-body">
                                                {/*<BotStatsNivoChart*/}
                                                {/*data={this.connvertToChartData(stat.followers, stat.following)}*/}
                                                {/*/>*/}

                                                <BotStatsHighChart
                                                    data={this.connvertToChartData(stat.followers, stat.following)}/>

                                            </div>
                                        </section>);
                                })}

                            </div>
                        </React.Fragment>
                    )}
                </main>
            </div>
        );
    }
}

export default BotsStatsView;
