import React from 'react';

import styled from 'styled-components';
import UserItem from './UserItem';

export const Styles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;;

  @media (max-width: 900px) {
    display: block;
  }
`;

const UsersList = ({ list }) => {
    return (
        <Styles>
            { list.map(user => {
                if (user) {
                    return (<UserItem key={ user._id } item={ user }/>);
                } else {
                    return null;
                }
            }) }
        </Styles>
    );
};

export default UsersList;