import React, {Component} from 'react';
import NavigationSide from "../../components/navigation-side/NavigationSide";
import TopPanel from "./components/TopPanel";

class DashboardView extends Component {
    render() {
        return (
            <div className='main-wrapper'>
                <NavigationSide/>
                <main>
                    <TopPanel/>
                </main>
            </div>
        );
    }
}

export default DashboardView;