import React, { Component } from 'react';
import catTypesStore from '../../../stores/cat-types.store';
import botsStore from '../../../stores/bots.store';
import NavigationSide from '../../components/navigation-side/NavigationSide';
import NavigationTop from '../../components/navigation-top/NavigationTop';
import LoaderComponent from '../../components/loader/Loader.component';
import utilsService from '../../../services/utils.service';
import { observer } from 'mobx-react';
import Ads from '../../components/Ads';
import MessagesEditJob from './messages/MessagesEditJob';
import messagesStore from '../../../stores/messages.store';

@observer
class BotsJobEditCreateView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            jobName: '',
            apiToCall: '',
            tags: '',
            apiSearch: '',
            id_for: '',
            customCaption: '',
            isActive: false,
            jobCategory: '',
            jobType: '',
            ratingKeyName: '',
            ratingValue: '',
            realInterval: false,
            useCaption: false,
            seconds: 0,
            minutes: 0,
            hours: 0,
            days: 0,
            ads: [],
            messages: [],
            _id: '',
            selectedAdToEdit: null,
            selectedAdToEditOpen: false,
            selectedAdToEditIndex: null
        };
    }

    async componentDidMount() {
        document.title = 'Create or Edit job - shemesh.info';
        if (catTypesStore.categories.length === 0 && botsStore.totalJobs ===
            0) {
            await Promise.all([
                botsStore.getBots(),
                catTypesStore.getCats()
            ]);

            this.setState({ loader: false });
        } else if (catTypesStore.categories.length === 0) {
            await catTypesStore.getCats();
            this.setState({ loader: false });
        } else if (botsStore.totalJobs === 0) {
            await botsStore.getBots();
            this.setState({ loader: false });
        }

        await messagesStore.getMessages();

        const { id } = this.props.match.params;
        if (id) {
            const newState = { ...this.state, ...botsStore.botsObj[id] };
            utilsService.calculateIntervalToShow(newState.interval);
            newState.seconds = utilsService.secs;
            newState.minutes = utilsService.mins;
            newState.hours = utilsService.hours;
            newState.days = utilsService.days;
            this.setState(newState);
            this.setState({ loader: false });
        }
    }

    handleInputChange = (e, type) => {
        this.setState({
            [type]: e.target.value
        });
    };

    handleButtonClick = (type, e) => {
        e.preventDefault();
        const oldState = this.state[type];
        this.setState({
            [type]: !oldState
        });
    };

    handleSelectChange = (e, type) => {
        this.setState({
            [type]: e.target.value
        });
    };

    handleSave = async (e) => {
        e.preventDefault();
        const { id } = this.props.match.params;
        await this.setState({
            interval: utilsService.calculateInterval(this.state.days,
                this.state.hours, this.state.minutes, this.state.seconds
            )
        });
        await botsStore.postEditJob(this.state);
        if (!id) {
            this.props.history.push('/bots/jobs/');
        }
    };

    handleDelete = async (e) => {
        e.preventDefault();
        if (this.state.jobName) {
            await botsStore.deleteJob(this.state.jobName);
            this.props.history.push('/bots/jobs/');
        }
    };

    editAd = (e, item, dix) => {
        e.preventDefault();
        this.setState({
            selectedAdToEdit: item,
            selectedAdToEditIndex: dix,
            selectedAdToEditOpen: true
        });
    };

    setMessages = item => {
        let messages = [...this.state.messages];
        const isPresent = messages.find(dd => dd.messageId === item._id);

        if (isPresent) {
            messages = messages.filter(dd => dd.messageId !== item._id);
        } else {
            messages.push({
                messageId: item._id,
                name: item.name
            });
        }

        this.setState({ messages: messages });
    };

    addAd = data => {
        const ads = [...this.state.ads];
        const index = this.state.selectedAdToEditIndex;

        if (index != null && index >= 0) {
            ads[index].name = data.name;
            ads[index].enabled = data.enabled;
            ads[index].link = data.link;
            ads[index].images = data.images;
        } else {
            ads.push(data);
        }
        this.setState({
            ads: ads,
            selectedAdToEditIndex: null,
            selectedAdToEdit: null,
            selectedAdToEditOpen: false
        });
    };

    add = e => {
        e.preventDefault();
        this.setState({
            selectedAdToEditOpen: true
        });
    };

    render() {
        const cats = catTypesStore.categories;
        const types = catTypesStore.types;

        const secmins = Array(60).fill(0).map((x, i) => i);
        const hours = Array(24).fill(0).map((x, i) => i);
        const days = Array(28).fill(0).map((x, i) => i);

        const isAds = this.state.jobCategory === 'ads';

        return (
            <div className="main-wrapper">
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    { this.state.loader && (<LoaderComponent/>) }

                    { !this.state.loader && (
                        <React.Fragment>
                            <h1>Job View</h1>
                            <div className="job-edit-wrapper">
                                <section className="job-edit panel-x">
                                    <div className="panel-header">
                                        <h3>{ this.state.jobName }</h3>
                                    </div>
                                    <div className="panel-body">
                                        <form className="form">
                                            <div className="form-item">
                                                <label>
                                                    Job Name
                                                </label>
                                                <input
                                                    onChange={ (e) => this.handleInputChange(
                                                        e, 'jobName') }
                                                    value={ this.state.jobName }
                                                    type="text"/>
                                            </div>
                                            <div className="form-item">
                                                <label>
                                                    Api To Call
                                                </label>
                                                <input
                                                    onChange={ (e) => this.handleInputChange(
                                                        e, 'apiToCall') }
                                                    value={ this.state.apiToCall !=
                                                    null
                                                        ? this.state.apiToCall
                                                        : '' }
                                                    type="text"/>
                                            </div>
                                            <div className="form-item">
                                                <label>
                                                    Tags
                                                </label>
                                                <input
                                                    onChange={ (e) => this.handleInputChange(
                                                        e, 'tags') }
                                                    value={ this.state.tags }
                                                    type="text"/>
                                            </div>
                                            <div className="form-item">
                                                <label>
                                                    Api Search
                                                </label>
                                                <input
                                                    onChange={ (e) => this.handleInputChange(
                                                        e, 'apiSearch') }
                                                    value={ this.state.apiSearch }
                                                    type="text"/>
                                            </div>
                                            <div className="form-item">
                                                <label>
                                                    Id for
                                                </label>
                                                <input
                                                    onChange={ (e) => this.handleInputChange(
                                                        e, 'id_for') }
                                                    value={ this.state.id_for ==
                                                    null
                                                        ? ''
                                                        : this.state.id_for }
                                                    type="text"/>
                                            </div>
                                            <div className="form-item">
                                                <label>
                                                    Custom Caption
                                                </label>
                                                <input
                                                    onChange={ (e) => this.handleInputChange(
                                                        e, 'customCaption') }
                                                    value={ this.state.customCaption ==
                                                    null
                                                        ? ''
                                                        : this.state.customCaption }
                                                    type="text"/>
                                            </div>
                                            <div className="form-item">
                                                <label>
                                                    Rating from
                                                </label>
                                                <div className="two-her">
                                                    <input
                                                        placeholder={ 'ratingKeyName' }
                                                        onChange={ (e) => this.handleInputChange(
                                                            e,
                                                            'ratingKeyName'
                                                        ) }
                                                        value={ this.state.ratingKeyName ==
                                                        null
                                                            ? ''
                                                            : this.state.ratingKeyName }
                                                        type="text"/>
                                                    <input
                                                        placeholder={ 'ratingValue' }
                                                        onChange={ (e) => this.handleInputChange(
                                                            e, 'ratingValue') }
                                                        value={ this.state.ratingValue ==
                                                        null
                                                            ? ''
                                                            : this.state.ratingValue }
                                                        type="text"/>
                                                </div>
                                            </div>
                                            <MessagesEditJob
                                                messages={ this.state.messages }
                                                messagesList={ messagesStore.messages }
                                                setMessages={ this.setMessages }
                                            />
                                            <div className="form-item">
                                                <label>
                                                    Interval
                                                </label>
                                                <div
                                                    className="form-selector-group">
                                                    <select
                                                        value={ this.state.seconds }
                                                        onChange={ (e) => this.handleSelectChange(
                                                            e, 'seconds') }
                                                    >
                                                        { secmins.map(
                                                            (sec, idx) => {
                                                                return (<option
                                                                    key={ idx }
                                                                    value={ sec }>{ sec } seconds</option>);
                                                            }) }
                                                    </select>
                                                    <select
                                                        value={ this.state.minutes }
                                                        onChange={ (e) => this.handleSelectChange(
                                                            e, 'minutes') }
                                                    >
                                                        { secmins.map(
                                                            (sec, idx) => {
                                                                return (<option
                                                                    key={ idx }
                                                                    value={ sec }>{ sec } mins</option>);
                                                            }) }
                                                    </select>
                                                    <select
                                                        value={ this.state.hours }
                                                        onChange={ (e) => this.handleSelectChange(
                                                            e, 'hours') }
                                                    >
                                                        { hours.map(
                                                            (sec, idx) => {
                                                                return (<option
                                                                    key={ idx }
                                                                    value={ sec }>{ sec } hours</option>);
                                                            }) }
                                                    </select>
                                                    <select
                                                        value={ this.state.days }
                                                        onChange={ (e) => this.handleSelectChange(
                                                            e, 'days') }
                                                    >
                                                        { days.map(
                                                            (sec, idx) => {
                                                                return (<option
                                                                    key={ idx }
                                                                    value={ sec }>{ sec } days</option>);
                                                            }) }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-item">
                                                <label>
                                                    Category
                                                </label>
                                                <div
                                                    className="form-selector-group">
                                                    <select
                                                        value={ this.state.jobCategory }
                                                        onChange={ (e) => this.handleSelectChange(
                                                            e, 'jobCategory') }
                                                    >
                                                        { cats.map(
                                                            (cat, idx) => {
                                                                return (<option
                                                                    key={ cat.name +
                                                                        idx }
                                                                    value={ cat.name }>{ cat.name }</option>);
                                                            }) }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-item">
                                                <label>
                                                    Type
                                                </label>
                                                <div
                                                    className="form-selector-group">
                                                    <select
                                                        value={ this.state.jobType }
                                                        onChange={ (e) => this.handleSelectChange(
                                                            e, 'jobType') }
                                                    >
                                                        { types.map(
                                                            (type, idx) => {
                                                                return (<option
                                                                    key={ type.name +
                                                                        idx }
                                                                    value={ type.name }>{ type.name }</option>);
                                                            }) }
                                                    </select>
                                                </div>
                                            </div>
                                            { isAds && (
                                                <>
                                                    <div className="form-item">
                                                        <label>
                                                            Ads
                                                        </label>
                                                        <div
                                                            className="perads-item-list-wrap">
                                                            { this.state.ads.length >
                                                                0 && (
                                                                    this.state.ads.map(
                                                                        (
                                                                            item,
                                                                            dix) => {
                                                                            return (
                                                                                <div
                                                                                    key={ dix +
                                                                                        item.name }
                                                                                    className="perads-item">
                                                                                    <div
                                                                                        className="perads-item-adper">
                                                                                        <label>Name</label>
                                                                                        <p>{ item.name }</p>
                                                                                    </div>
                                                                                    <div
                                                                                        className="perads-item-adper">
                                                                                        <label>Link</label>
                                                                                        <p>{ item.link }</p>
                                                                                    </div>
                                                                                    <div
                                                                                        className="perads-item-adper">
                                                                                        <label>Enabled</label>
                                                                                        <p>{ item.enabled
                                                                                            ? 'Yes'
                                                                                            : 'No' }</p>
                                                                                    </div>
                                                                                    <div
                                                                                        className="perads-item-adper images-list">
                                                                                        <label>Images</label>
                                                                                        { item.images &&
                                                                                            item.images.map(
                                                                                                (
                                                                                                    pew,
                                                                                                    idx) => {
                                                                                                    return (
                                                                                                        <p
                                                                                                            key={ idx }
                                                                                                        >{ pew }</p>
                                                                                                    );
                                                                                                }) }
                                                                                    </div>
                                                                                    <div
                                                                                        className="perads-item-adper">
                                                                                        <label>Count</label>
                                                                                        <p>{ item.count }</p>
                                                                                    </div>
                                                                                    <div
                                                                                        className="perads-item-adper-edit">
                                                                                        <button
                                                                                            onClick={ e => this.editAd(
                                                                                                e,
                                                                                                item,
                                                                                                dix
                                                                                            ) }
                                                                                            className="button suc">Edit
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                ) }

                                                            { this.state.ads.length ===
                                                                0 && (
                                                                    <div>No
                                                                        ads</div>
                                                                ) }

                                                            <div
                                                                className="pew-pew">
                                                                <button
                                                                    onClick={ this.add }
                                                                    className="button suc">Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="addsss-item">
                                                        { this.state.selectedAdToEditOpen &&
                                                            (
                                                                <div
                                                                    className="ads-edit">
                                                                    <Ads
                                                                        addAd={ data => this.addAd(
                                                                            data) }
                                                                        item={ this.state.selectedAdToEdit }
                                                                    />

                                                                </div>
                                                            ) }
                                                    </div>
                                                </>
                                            ) }
                                            <div
                                                className="form-item action-buttons">
                                                <button
                                                    onClick={ (e) => this.handleButtonClick(
                                                        'isActive', e) }
                                                    className={ this.state.isActive
                                                        ? 'button info'
                                                        : 'button def' }>
                                                    { this.state.isActive
                                                        ? 'Active'
                                                        : 'Not Active' }
                                                </button>
                                                <button
                                                    onClick={ (e) => this.handleButtonClick(
                                                        'realInterval', e) }
                                                    className={ this.state.realInterval
                                                        ? 'button info'
                                                        : 'button def' }>Real
                                                    Interval
                                                </button>
                                                <button
                                                    onClick={ (e) => this.handleButtonClick(
                                                        'useCaption', e) }
                                                    className={ this.state.useCaption
                                                        ? 'button info'
                                                        : 'button def' }>Use
                                                    Caption
                                                </button>
                                            </div>
                                            <div className="submit-delete">
                                                <button
                                                    onClick={ this.handleSave }
                                                    disabled={ botsStore.working }
                                                    className="button suc">
                                                    Save
                                                </button>
                                                <button
                                                    onClick={ this.handleDelete }
                                                    disabled={ botsStore.working }
                                                    className="button dang">
                                                    Delete
                                                </button>
                                            </div>

                                        </form>
                                    </div>
                                </section>
                                <section className="job-history panel-x">
                                    <div className="panel-header">
                                        <h3>Job History</h3>
                                    </div>
                                </section>
                            </div>
                        </React.Fragment>
                    ) }
                </main>
            </div>
        );
    }
}

export default BotsJobEditCreateView;
