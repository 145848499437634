import React, { Component } from 'react';
import { observer } from 'mobx-react';
import messagesStore from '../../../../stores/messages.store';
import NavigationSide from '../../../components/navigation-side/NavigationSide';
import NavigationTop from '../../../components/navigation-top/NavigationTop';
import LoaderComponent from '../../../components/loader/Loader.component';
import { Link } from 'react-router-dom';
import MessagesList from './MessagesList';

@observer
class MessagesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }

    async componentDidMount() {
        document.title = 'Messages - shemesh.info';
        await messagesStore.getMessages();
        this.setState({ loader: false });
    }

    render() {
        const { loader } = this.state;
        const messages = messagesStore.messages;

        return (
            <div className="main-wrapper">
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    { loader && (<LoaderComponent/>) }

                    { !loader && (
                        <React.Fragment>
                            <h1>Messages</h1>
                            <div>
                                <Link
                                    className="button suc"
                                    to={ '/bots/messages-edit' }>
                                    Create New Message
                                </Link>
                            </div>
                            <MessagesList
                                list={ messages }
                            />
                        </React.Fragment>
                    ) }

                </main>
            </div>
        );
    }
}

export default MessagesView;