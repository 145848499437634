import React, { Component } from 'react';
import { observer } from 'mobx-react';
import syagentStore from '../../../../stores/syagent.store';
import NavigationSide from '../../../components/navigation-side/NavigationSide';
import NavigationTop from '../../../components/navigation-top/NavigationTop';
import LoaderComponent from '../../../components/loader/Loader.component';
import ServersList from './ServersList';

@observer
class SyagentServersView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }

    async componentDidMount() {
        document.title = 'Servers - syagent';
        await syagentStore.getServers();
        this.setState({ loader: false });
    }

    render() {
        const servers = syagentStore.servers;
        const total = servers.length;

        return (
            <div className="main-wrapper">
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    { this.state.loader && (<LoaderComponent/>) }

                    { !this.state.loader && (
                        <>
                            <h1>Servers</h1>
                            <h3>Total: { total }</h3>
                            <ServersList
                                list={ servers.slice() }
                            />
                        </>
                    ) }
                </main>
            </div>

        );
    }
}

export default SyagentServersView;