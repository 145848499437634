import { action, observable } from 'mobx';
import fetchService from '../services/fetch.service';

class MessagesStore {
    @observable messages = [];

    @action.bound
    async getMessages() {
        const resp = await fetchService.authenticatedGet(
            '/huj/messages');
        this.messages = resp.data;
    }

    async getMessage(id) {
        const resp = await fetchService.authenticatedGet(
            '/huj/messages/' + id);
        return resp.data;
    }

    async createMessage(payload) {
        const resp = await fetchService.authenticatedPost(
            '/huj/messages', payload);
        this.messages = resp.data;
    }

    async updateMessage(id, payload) {
        const resp = await fetchService.authenticatedPut(
            `/huj/messages/${ id }`, payload);
        this.messages = resp.data;
    }

    async sendMessage(payload) {
        const resp = await fetchService.authenticatedPost(
            '/huj/send-message', payload);
        this.messages = resp.data;
    }
}

const messagesStore = new MessagesStore();

export default messagesStore;
