import React, {Component} from 'react';
import NavigationSide from '../../components/navigation-side/NavigationSide';
import NavigationTop from '../../components/navigation-top/NavigationTop';
import LoaderComponent from '../../components/loader/Loader.component';
import catTypesStore from '../../../stores/cat-types.store';
import botsStore from '../../../stores/bots.store';
import {observer} from 'mobx-react';
import { Link } from 'react-router-dom';

@observer
class CategoriesAndTypesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            category: '',
            type: ''
        };
    }

    async componentDidMount() {
        document.title = 'Categories and Types List - shemesh.info';
        if (catTypesStore.categories.length === 0) {
            await catTypesStore.getCats();
        }
        this.setState({ loader: false });
    }

    inputOnchange = (event, valueType) => {
        this.setState({
            [valueType]: event.target.value
        });
    };

    createData = async (e, isType) => {
        const { category, type } = this.state;
        if (isType) {
            await catTypesStore.createType(type);
        } else {
            await catTypesStore.createCategory(category);
        }

    };

    render() {
        const cat = catTypesStore.categories;
        const typ = catTypesStore.types;

        return (
            <div className='main-wrapper'>
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    {this.state.loader && (<LoaderComponent/>)}

                    {!this.state.loader && (
                        <React.Fragment>
                            <h1>Categories and Types List</h1>

                            <div className="job-edit-wrapper">
                                <section className="panel-x">
                                    <div className="panel-header">
                                        <h3>List of Categories</h3>
                                    </div>
                                    <div className="panel-body">

                                        <table className="table is-striped margin-t">
                                            <thead>
                                            <tr>
                                                <th>
                                                    Category Name
                                                </th>
                                                <th colSpan="2">
                                                    Actions
                                                </th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {cat.map((category, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{category.name}</td>
                                                        <td>
                                                            <Link
                                                                className='button suc'
                                                                to={'/bots/cat-types/' + category._id}>
                                                                Edit
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <button
                                                                disabled={botsStore.working}
                                                                className="button dang">
                                                                Delete
                                                            </button>
                                                        </td>
                                                    </tr>);
                                            })}

                                            </tbody>
                                        </table>

                                    </div>
                                </section>
                                <section className="panel-x">
                                    <div className="panel-header">
                                        <h3>List of Types</h3>
                                    </div>
                                    <div className="panel-body">
                                        <table className="table is-striped margin-t">
                                            <thead>
                                            <tr>
                                                <th>
                                                    Type Name
                                                </th>
                                                <th colSpan="2">
                                                    Actions
                                                </th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {typ.map((category, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{category.name}</td>


                                                        <td>
                                                            <Link
                                                                className='button suc'
                                                                to={'/bots/cat-types/' + category._id}>
                                                                Edit
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <button
                                                                disabled={botsStore.working}
                                                                className="button dang">
                                                                Delete
                                                            </button>
                                                        </td>
                                                    </tr>);
                                            })}

                                            </tbody>
                                        </table>
                                    </div>
                                </section>

                                <div className="create-shit">
                                    <div className="cat">
                                        <h3>Create category</h3>
                                        <input
                                            onChange={event => this.inputOnchange(event, 'category')}
                                            type="text"/>
                                        <button
                                            onClick={e => this.createData(e)}
                                            className='button suc'>Add
                                        </button>
                                    </div>
                                    <div className="type">
                                        <h3>Create type</h3>
                                        <input
                                            onChange={event => this.inputOnchange(event, 'type')}
                                            type="text"/>
                                        <button
                                            onClick={e => this.createData(e, true)}
                                            className='button suc'>Add
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </main>
            </div>
        );
    }
}

export default CategoriesAndTypesView;
