import { action, observable } from 'mobx';
import fetchService from '../services/fetch.service';

class NoShitStore {
    @observable users = [];
    @observable comments = [];

    @action.bound
    async getUsers() {
        const users = await fetchService.authenticatedGet('/huj/noshit/users');
        this.users = users.data;
    }

    @action.bound
    async getComments() {
        const comments = await fetchService.authenticatedGet('/huj/noshit/comments');
        this.comments = comments.data;
    }
}

const noShitStore = new NoShitStore();

export default noShitStore;