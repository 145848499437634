import { action, observable } from 'mobx';
import fetchService from '../services/fetch.service';

class TextmeStore {
    @observable users = [];
    @observable comments = [];

    @action.bound
    async getUsers() {
        const users = await fetchService.authenticatedGet('/huj/textme/users');
        this.users = users.data;
    }

    @action.bound
    async getComments() {
        const comments = await fetchService.authenticatedGet('/huj/textme/comments');
        this.comments = comments.data;
    }
}

const textmeStore = new TextmeStore();

export default textmeStore;