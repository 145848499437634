import React from 'react';
import NavigationSide from "./navigation-side/NavigationSide";
import NavigationTop from "./navigation-top/NavigationTop";
import LoaderComponent from "./loader/Loader.component";

const CommonWrapperComponent = (props) => {
    return (
        <div className='main-wrapper'>
            <NavigationSide/>
            <main>
                <NavigationTop/>
                {props.loader && (<LoaderComponent/>)}

                {!props.loader && (
                    <React.Fragment>
                        <h1>{props.title}</h1>

                        {props.children}
                    </React.Fragment>
                )}
            </main>
        </div>
    );
};

export default CommonWrapperComponent;
