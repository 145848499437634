import React, { Component } from 'react';
import { observer } from 'mobx-react';
import syagentStore from '../../../../stores/syagent.store';
import NavigationSide from '../../../components/navigation-side/NavigationSide';
import NavigationTop from '../../../components/navigation-top/NavigationTop';
import LoaderComponent from '../../../components/loader/Loader.component';
import UserInfo from './UserInfo';

@observer
class UserView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }

    async componentDidMount() {
        document.title = 'User - syagent';
        const { id } = this.props.match.params;

        await syagentStore.getUser(id);
        await syagentStore.getServersByUser(id);
        await syagentStore.getSentAlertsByUser(id);
        this.setState({ loader: false });
    }

    render() {
        const user = syagentStore.user;
        const servers = syagentStore.serversByUser;
        const sentAlerts = syagentStore.sentAlertsByUser;

        return (
            <div className="main-wrapper">
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    { this.state.loader && (<LoaderComponent/>) }

                    { !this.state.loader && (
                        <>
                            <h1>Users</h1>
                            <UserInfo
                                servers={ servers }
                                user={ user }
                                sentAlerts={ sentAlerts }
                            />
                        </>
                    ) }
                </main>
            </div>

        );
    }
}

export default UserView;