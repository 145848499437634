import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Styles = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  position: relative;
  background: #fff;
  border: 1px solid #e6e9ed;
  -webkit-column-break-inside: avoid;
  column-break-inside: avoid;
  opacity: 1;
  transition: all .2s ease;
`;

const StylesData = styled.div`
  span {
    font-weight: bold;
  }
`;

const CommentItem = ({ item }) => {
    return (
        <Styles>
            <StylesData>
                Link to post: <span><a href={ `https://juicyelf.com/p/${ item.slug }` } target="_blank">Follow</a> </span>
            </StylesData>
            <StylesData>
                Comment body: <span>{ item.body }</span>
            </StylesData>
            <StylesData>
                isDeleted: <span>{ item.isDeleted ? 'true' : 'false' }</span>
            </StylesData>
            <StylesData>
                Telegram ID: <span>{ item.user.userId }</span>
            </StylesData>
            <StylesData>
                Role: <span>{ item.user.role }</span>
            </StylesData>
            <StylesData>
                item status: <span>{ item.user.itemStatus }</span>
            </StylesData>
            <StylesData>
                blockedReason: <span>{ item.user.blockedReason }</span>
            </StylesData>
            <StylesData>
                blockedUntil: <span>{ moment(item.user.blockedUntil).format('HH:mm, MM-DD-YYYY') }</span>
            </StylesData>
        </Styles>
    );
};

export default CommentItem;