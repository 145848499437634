import React, { Component } from 'react';

class Log extends Component {
    showHideHandle = () => {
        const { open, index } = this.props;
        open(index);
    };

    render() {
        const log = this.props.data;

        if (!log) {
            return null;
        }

        const { index, style } = this.props;
        return (
            <div className="log-component"
                 style={ style }
                 key={ index }
            >
                <div className="log-visible-part"
                     onClick={ this.showHideHandle }>
                    <div className="date">
                        { log.type !== 'info' && (
                            <i className="fas fa-exclamation-triangle"/>
                        ) }
                        [{ log.createdAt }]
                        - { log.job } - { log.message } | { log.functionName }
                    </div>
                </div>
            </div>
        );
    }
}

export default Log;
