import React from 'react';
import moment from "moment";
import DataGrid, {Pager, Paging, SearchPanel, Column} from 'devextreme-react/data-grid';
import ServerInfoComponent from "../server-info/ServerInfo.component";
import ServerTotalInfo from "../server-total-info/ServerTotalInfo";
import ServerTotalInfoVertical from "../server-total-info-vertical/ServerTotalInfoVertical";
import CpuSpinner from "../cpu-spinner/CpuSpinner";

const biteGb = 1024 * 1024 * 1024;
const biteMg = 1024 * 1024;

const cpuColumnFormater = (data) => data.value + '%';
const memoryColumnFormater = (data) => Math.round(data.value / (1024 * 1024) * 100) / 100 + 'Mb';

const ServerOverviewComponent = (props) => {
    return (
        <section className="overview">
            <div className="overview-clumns panel-x">
                <div className="item">
                    <div className="server-panel gap">
                        <header>
                            <h3>Server</h3>
                        </header>
                        <div className="server-body">
                            <div className="jkl">
                                Name: <span>{props.do.name}</span>
                            </div>
                            <div className="jkl">
                                Availability: <span>{props.nq.availability}</span>
                            </div>
                            <div className="jkl">
                                {/*System uptime: <span>{moment(props.nq.system_uptime).format('h')}</span>*/}
                                System
                                uptime: <span>{moment(Date.now() - props.nq.system_uptime * 1000).fromNow(true)}</span>
                            </div>
                            <div className="jkl">
                                Status: <span>{props.do.status}</span>
                            </div>
                            <div className="jkl">
                                OS: <span>{props.nq.os_name} ({props.nq.os_arch})</span>
                            </div>
                            <div className="jkl">
                                Creation date: <span>{moment(props.do.created_at).format('DD/MM/YYYY')}</span>
                            </div>
                            <ServerInfoComponent
                                title='Sessions'
                                data={props.nq.sessions}
                            />
                        </div>
                    </div>

                    <div className=" server-panel memory">
                        <header>
                            <h3>Memory</h3>
                        </header>
                        <div className="server-body">
                            <div className="jkl">
                                Total Memory: <span>{parseInt(props.nq.ram_total / biteMg, 10)} Mb</span>
                            </div>

                            <ServerTotalInfo
                                title='Memory Usage'
                                start={0}
                                current={props.nq.ram_usage / biteMg}
                                total={props.nq.ram_total / biteMg}
                                ext='Mb'
                            />
                            <ServerTotalInfo
                                title='Swap Usage'
                                start={0}
                                current={props.nq.swap_usage}
                                total={props.nq.swap_total}
                            />
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className=" server-panel gap">
                        <header>
                            <h3>CPU</h3>
                        </header>
                        <div className="server-body">
                            <div className="jkl">
                                CPU brand: <span>{props.nq.cpu_name}</span>
                            </div>
                            <ServerInfoComponent
                                title='CPU cores'
                                data={props.nq.cpu_cores}
                            />

                            <ServerInfoComponent
                                title='CPU frequency'
                                data={props.nq.cpu_freq}
                            />

                            <div className="spinner">
                                <h5>CPU</h5>
                                <CpuSpinner
                                    load={props.nq.load_percent}
                                />
                            </div>
                        </div>
                    </div>

                    <div className=" server-panel">
                        <header>
                            <h3>Processes</h3>
                        </header>
                        <div className="server-body">
                            <ServerInfoComponent
                                title='Total processe'
                                data={props.nq.processes}
                            />

                            <ServerInfoComponent
                                title='Top processes'
                                data={props.nq.processes_array.length}
                            />

                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className=" server-panel network">
                        <header>
                            <h3>Network</h3>
                        </header>
                        <div className="server-body">
                            <ServerInfoComponent
                                title='Current Outgoing'
                                ext={props.nq.current_tx / biteGb > 1 ? 'Gb' : 'Mb'}
                                data={props.nq.current_tx / biteGb > 1 ? props.nq.current_tx / biteGb : props.nq.current_txl / biteMg}
                            />
                            <ServerInfoComponent
                                title='Current Incoming'
                                ext={props.nq.current_rx / biteGb > 1 ? 'Gb' : 'Mb'}
                                data={props.nq.current_rx / biteGb > 1 ? props.nq.current_rx / biteGb : props.nq.current_rx / biteMg}
                            />
                            <ServerInfoComponent
                                title='Total Outgoing'
                                ext={props.nq.total_tx / biteGb > 1 ? 'Gb' : 'Mb'}
                                data={props.nq.total_tx / biteGb > 1 ? props.nq.total_tx / biteGb : props.nq.total_tx / biteMg}
                            />
                            <ServerInfoComponent
                                title='Total Incoming'
                                ext={props.nq.total_rx / biteGb > 1 ? 'Gb' : 'Mb'}
                                data={props.nq.total_rx / biteGb > 1 ? props.nq.total_rx / biteGb : props.nq.total_rx / biteMg}
                            />

                            <div className="jkl">
                                NIC : <span>{props.nq.nic}</span>
                            </div>

                            <h4 className='net-puk'>Networks</h4>

                            {Object.keys(props.do.networks.v4[0]).map((key, idx) => {
                                return (
                                    <div className="jkl" key={idx}>
                                        {key} : <span>{props.do.networks.v4[0][key]}</span>
                                    </div>
                                );
                            })}

                            <div className="jkl">
                                Region : <span>{props.do.region.name}</span>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className=" server-panel disk">
                        <header>
                            <h3>Disk</h3>
                        </header>
                        <div className="server-body">
                            <div className="jkl">
                                Number of Disks : <span>{props.nq.disk_array.length}</span>
                            </div>
                            {props.nq.disk_array.map((disk, idx) => {
                                return (
                                    <ServerTotalInfoVertical
                                        key={idx}
                                        title={disk.label}
                                        start={0}
                                        current={disk.usage / biteGb > 1 ? disk.usage / biteGb : disk.usage / biteMg}
                                        total={disk.total / biteGb > 1 ? disk.total / biteGb : disk.total / biteMg}
                                        ext={disk.usage / biteGb > 1 ? 'Gb' : 'Mb'}
                                    />
                                );
                            })}

                            <ServerTotalInfo
                                title='Disk Total Usage'
                                start={0}
                                current={props.nq.disk_usage / biteGb}
                                total={props.nq.disk_total / biteGb}
                                ext='Gb'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="data-table">
                <h2>Top Processes</h2>
                <DataGrid
                    dataSource={props.nq.processes_array}
                    columns={['command', 'count', 'cpu', 'memory', 'user']}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                >

                    <Column
                        dataField={'command'}
                    />
                    <Column
                        dataField={'count'}
                    />
                    <Column
                        dataField={'cpu'}
                        cellRender={cpuColumnFormater}
                    />
                    <Column
                        dataField={'memory'}
                        cellRender={memoryColumnFormater}
                    />

                    <Column
                        dataField={'user'}
                    />

                    <SearchPanel visible={true}
                                 width={240}
                                 placeholder={'Search...'}/>
                    <Paging pageSize={20}/>
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 20, 50]}
                        showInfo={true}/>
                </DataGrid>
            </div>
        </section>
    );
};

export default ServerOverviewComponent;
