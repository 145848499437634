import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import DataGrid, {Pager, Paging, SearchPanel, Column} from 'devextreme-react/data-grid';
import aliStore from "../../../../stores/ali.store";
import NavigationSide from "../../../components/navigation-side/NavigationSide";
import NavigationTop from "../../../components/navigation-top/NavigationTop";
import LoaderComponent from "../../../components/loader/Loader.component";

class AliDataView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        }
    }

    imageColumnRender = (data) => <img className='data-grid-image' src={data.value}/>;

    titleColumnRender = (data) => {
        return (
            <Link to={'/ali/data/' + data.data._id}>{data.value}</Link>);

    };

    handleCatClick = async (e, cat) => {
        this.setState({loader: true});
        await aliStore.getData(cat);
        this.setState({loader: false});
    };

    postedInColumnRender = (data) => {
        return (
            <React.Fragment>
                {data.value.map((a, idx) => {
                    return (
                        <div
                            key={idx}
                        >{a}</div>
                    )
                })}
            </React.Fragment>
        )
    };

    async componentDidMount() {
        document.title = "Ali Data - shemesh.info";

        if (aliStore.categories.length === 0) {
            await aliStore.getCategories();
            await aliStore.getData(aliStore.curCat);
            this.setState({loader: false});
        } else {
            this.setState({loader: false});
        }
    }


    render() {
        const tempData = aliStore.aliData[aliStore.curCat];
        const dataLenght = tempData ? tempData.length : 0;

        const data = tempData ? tempData.map(item => {
            item.createdAt = item.createdAt.$date;
            return item;
        }) : [];


        return (
            <div className='main-wrapper'>
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    {this.state.loader && (<LoaderComponent/>)}

                    {!this.state.loader && (
                        <React.Fragment>
                            <h1>Aliexpress Data</h1>

                            <section className="ali-categories panel-x">
                                <ul>
                                    {aliStore.categories.map(cat => {
                                        return (
                                            <li
                                                className={aliStore.curCat === cat ? 'active' : ''}
                                                key={cat}
                                                onClick={(e) => this.handleCatClick(e, cat)}
                                            >{cat}</li>
                                        )
                                    })}
                                </ul>
                            </section>

                            <section className="data-grid panel-x">
                                <div className="data-grid-info">
                                    Category: <span className="huk">{aliStore.curCat}</span> | Total Items: <span
                                    className="huk">{dataLenght}</span>
                                </div>
                                <DataGrid
                                    dataSource={data}
                                    columns={['image_url', 'category', 'title', 'discount', 'sale_price', 'createdAt', 'posted_in']}
                                    showBorders={true}
                                    showColumnLines={true}
                                    showRowLines={true}
                                >

                                    <Column
                                        dataField={'image_url'}
                                        cellRender={this.imageColumnRender}
                                        width={150}
                                    />
                                    <Column
                                        dataField={'category'}
                                        width={150}
                                        alignment={'center'}
                                    />
                                    <Column
                                        dataField={'title'}
                                        cellRender={this.titleColumnRender}
                                    />
                                    <Column
                                        dataField={'discount'}
                                        width={80}
                                        alignment={'center'}
                                    />

                                    <Column
                                        dataField={'sale_price'}
                                        width={80}
                                        alignment={'center'}
                                    />

                                    <Column
                                        dataField={'createdAt'}
                                        dataType={'date'}
                                        width={120}
                                        alignment={'center'}
                                    />

                                    <Column
                                        dataField={'posted_in'}
                                        cellRender={this.postedInColumnRender}
                                        width={150}
                                        alignment={'center'}
                                    />

                                    <SearchPanel visible={true}
                                                 width={240}
                                                 placeholder={'Search...'}/>
                                    <Paging pageSize={20}/>
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={[10, 20, 50]}
                                        showInfo={true}/>
                                </DataGrid>
                            </section>
                        </React.Fragment>
                    )}
                </main>
            </div>
        );
    }
}

export default AliDataView;
