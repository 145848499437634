import React, { useState } from 'react';
import styled from 'styled-components';
import UserItem from './UserItem';
import ServersList from '../servers/ServersList';
import SentAlertsList from '../alerts/SentAlertsList';
import UserEdit from './UserEdit';

export const Styles = styled.div`

`;


const UserInfo = ({ user, servers, sentAlerts }) => {
    const [isEdit, setIsEdit] = useState(false);

    return (
        <Styles>
            <UserItem
                item={ user }
            />

            <button className="suc" onClick={ () => setIsEdit(!isEdit) }>
                Edit
            </button>

            { isEdit && (
                <UserEdit
                    user={ user }
                />
            ) }
            <ServersList
                list={ servers }
            />
            <SentAlertsList
                list={ sentAlerts }
            />
        </Styles>
    );
};

export default UserInfo;