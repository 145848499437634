import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Styles = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  position: relative;
  background: #fff;
  border: 1px solid #e6e9ed;
  -webkit-column-break-inside: avoid;
  column-break-inside: avoid;
  opacity: 1;
  transition: all .2s ease;
`;

const StylesData = styled.div`
  span {
    font-weight: bold;
  }
`;

const UserItem = ({ user }) => {
    return (
        <Styles>
            <StylesData>
                F L name: <span>{ user.first_name } { user.last_name }</span>
            </StylesData>
            <StylesData>
                Username: <span>{ user.username }</span>
            </StylesData>
            <StylesData>
                Telegram ID: <span>{ user.userId }</span>
            </StylesData>
            <StylesData>
                Role: <span>{ user.role }</span>
            </StylesData>
            <StylesData>
                User status: <span>{ user.userStatus }</span>
            </StylesData>
            <StylesData>
                blockedReason: <span>{ user.blockedReason }</span>
            </StylesData>
            <StylesData>
                blockedUntil: <span>{ moment(user.blockedUntil).format('HH:mm, MM-DD-YYYY') }</span>
            </StylesData>
        </Styles>
    );
};

export default UserItem;