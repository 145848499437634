import React from 'react';
import {Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export} from 'devextreme-react/chart';

const DbBarChart = (props) => {
    return (
        <Chart id={'chart'} dataSource={props.data}>
            <CommonSeriesSettings

                type={'bar'}
                hoverMode={'allArgumentPoints'}
                selectionMode={'allArgumentPoints'}
            >
            </CommonSeriesSettings>

            <Series
                valueField={'size'}
                argumentField={'collection'}
                name={'Size'}
            />
            <Series
                valueField={'totalIndexSize'}
                argumentField={'collection'}
                name={'Total Index Size'}
            />
            <Series
                valueField={'totalSize'}
                argumentField={'collection'}
                name={'Total Size'}
            />

            <Legend verticalAlignment={'bottom'} horizontalAlignment={'center'}/>
            <Export enabled={true}/>
        </Chart>

    );
};

export default DbBarChart;