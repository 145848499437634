import {action, observable} from 'mobx';
import fetchService from '../services/fetch.service';

class BotsUploadsStore {
    @observable list = [];
    @observable total = 0;
    @observable totalAll = 0;

    @action.bound
    async getList(catShit) {
        let uri = '/huj/bots-upload/get';
        if(catShit){
            uri+= `?name=${catShit}`;
        }

        const list = await fetchService.authenticatedGet(uri);
        this.list = list.data.posts;
        this.total = list.data.total;
        this.totalAll = list.data.totalPending;
    }

    @action.bound
    async edit(id, action, isBan = false) {
        await fetchService.authenticatedPost('/huj/bots-upload/edit', {_id: id, status: action, isBanned: isBan});
        this.list = this.list.filter(item => item._id !== id);
    }
}

const botsUploadsStore = new BotsUploadsStore();

export default botsUploadsStore;
