import React from 'react';
import ModalComponent from '../Modal.component';
import ReactJson from 'react-json-view';
import moment from 'moment';

const LogModalComponent = props => {
    const {log} = props;

    return (
        <ModalComponent onClose={() => props.closeEvent()} clickBodyToclose={true}>
            <div className='modal-box log-modal'>
                <div>
                    <h3 className="capitalized">Log, {log.job}</h3>
                    <div onClick={() => props.closeEvent()}
                         className='circle-close modal-close'>
                    </div>
                </div>
                <div className='scrollable-content custom-scroll'>
                    <div className="log-unvisible-part">
                        <ul>
                            <li> Type: <span className="logger-huj">{log.type}</span></li>
                            <li> Date: <span
                                className="logger-huj">{log.createdAt}</span></li>
                            <li> Message: <span className="logger-huj">{log.message}</span></li>
                            <li> Function Name: <span className="logger-huj">{log.functionName}</span></li>
                            <li> Job Name: <span className="logger-huj">{log.job}</span></li>
                            <li> Source: <span className="logger-huj">
                                {log.source && (
                                    <a target='_blank' href={'https://www.reddit.com' + log.source}>{log.source}</a>
                                )}
                            </span></li>
                            <li> JobId: <span className="logger-huj">{log.jobId}</span></li>
                        </ul>
                        {log.type !== 'info' && (
                            <div className="json-view">
                                <h3>Error Object</h3>
                                <ReactJson
                                    src={JSON.parse(log.error)}
                                    enableClipboard={false}
                                />
                            </div>
                        )}

                    </div>
                </div>
                <footer>
                    <button
                        onClick={() => props.closeEvent()}
                        className='button info'>
                        Close
                    </button>
                </footer>
            </div>
        </ModalComponent>
    );
};

export default React.memo(LogModalComponent);
