import React, {Component, useEffect} from 'react';
import botsUploadsStore from "../../../stores/botsUpload.store";
import styled from "styled-components";
import BotsUploadItem from "./BotsUploadItem";
import NavigationSide from "../../components/navigation-side/NavigationSide";
import NavigationTop from "../../components/navigation-top/NavigationTop";
import {observer} from "mobx-react";
import LoaderComponent from "../../components/loader/Loader.component";

export const Styles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;;

  @media (max-width: 900px) {
    display: block;
  }
`;

export const ActionsStyles = styled.div`
    display: grid;
    grid-template-columns: 130px 130px auto;
    margin: 10px 0;
`;

export const ActionsFilterStyles = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 50px);
`;

export const CatiesStyles = styled.div`
    cursor: pointer;
    color: deeppink;
`;

export const BublikActionsStyles = styled.div`
  font-size: 22px;
`;

const CATS = ['lesbian', 'porn', 'japs', 'hen', 'bw', 'feet', 'beauty'];

@observer
class BotsUploadsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }

    async componentDidMount() {
        document.title = 'Bots upload - Shit';
        await botsUploadsStore.getList();
        this.setState({loader: false});
    }

    getData = async (filter) =>{
        this.setState({loader: true});
        await botsUploadsStore.getList(filter);
        this.setState({loader: false});
    }

    render() {
        return (
            <div className="main-wrapper">
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    { this.state.loader && (<LoaderComponent/>) }
                    { !this.state.loader && (
                        <>
                            <ActionsStyles>
                                <BublikActionsStyles>
                                    Total: {botsUploadsStore.total}
                                </BublikActionsStyles>
                                <BublikActionsStyles>
                                    TotalAll: {botsUploadsStore.totalAll}
                                </BublikActionsStyles>
                                <ActionsFilterStyles>
                                    {CATS.map(catie => {
                                        return (
                                            <CatiesStyles key={catie} onClick={() => {
                                                this.getData(catie);
                                            }}>
                                                {catie}
                                            </CatiesStyles>
                                        )
                                    })}
                                </ActionsFilterStyles>
                            </ActionsStyles>
                            <Styles>
                                {botsUploadsStore.list.map(item => {
                                    return (
                                        <BotsUploadItem
                                            key={item._id}
                                            item={item}
                                        />
                                    );
                                })}
                            </Styles>
                        </>
                    )}
                </main>
            </div>)
    }
}

export default BotsUploadsView;
