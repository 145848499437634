import {action, observable} from "mobx";
import fetchService from "../services/fetch.service";

class AliStore {
    @observable csvs = [];
    @observable categories = [];
    @observable curCat = null;
    @observable aliData = {};

    @action.bound
    async getCsv() {
        const resp = await fetchService.authenticatedGet('/huj/ali/csvs');
        this.csvs = resp.data;
    }

    @action.bound
    async parseCSV(id) {
        const resp = await fetchService.authenticatedGet('/huj/ali/csvs-parse/' + id);
        this.csvs = resp.data;
    }

    @action.bound
    async deleteCSV(id) {
        const resp = await fetchService.authenticatedDelete('/huj/ali/csvs/' + id);
        this.csvs = resp.data;
    }

    @action.bound
    async postCSVs(csvsArr) {
        const resp = await fetchService.authenticatedPost('/huj/ali/csvs', csvsArr);
        this.csvs = resp.data;
    }


    @action.bound
    async getCategories() {
        const resp = await fetchService.authenticatedGet('/huj/ali/categories');
        this.categories = resp.data;
        this.curCat = this.categories[0];
    }

    @action.bound
    async getData(cat) {
        this.curCat = cat;
        const resp = await fetchService.authenticatedGet('/huj/ali/data/' + cat);
        this.aliData[cat] = resp.data;
    }

}

const aliStore = new AliStore();

export default aliStore;
