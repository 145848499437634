import React, { Component } from 'react';
import catTypesStore from '../../../stores/cat-types.store';
import botsStore from '../../../stores/bots.store';
import NavigationSide from '../../components/navigation-side/NavigationSide';
import NavigationTop from '../../components/navigation-top/NavigationTop';
import LoaderComponent from '../../components/loader/Loader.component';

class CategoriesAndTypesEditView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            name: '',
            type: '',
            configs: {},
            itemId: '',
            platformName: ''
        };
    }

    async componentDidMount() {
        document.title = 'Category edit - shemesh.info';
        if (catTypesStore.categories.length === 0) {
            await Promise.all([
                botsStore.getBots(),
                catTypesStore.getCats()
            ]);
        } else {
            await botsStore.getBots();
        }

        const { id } = this.props.match.params;
        let item = catTypesStore.categories.find(r => r._id === id);
        if (!item) {
            item = catTypesStore.types.find(r => r._id === id);
        }

        const config = item.configs;

        if (!config.itemId) {
            config.itemId = '';
            config.platformName = '';
            config.customData = '';
            config.customData2 = '';
            config.customData3 = '';
            config.keys = [];
        }

        const platformName = config.platformName;
        const itemId = config.itemId;

        const newState = { ...this.state, ...item };
        newState.loader = false;
        newState.itemId = itemId;
        newState.platformName = platformName;

        if (!item) {
            return;
        }
        this.setState(newState);
    }

    handleInputChange = (e, type) => {
        this.setState({
            [type]: e.target.value
        });
    };

    handleSave = async e => {
        e.preventDefault();
        const { type, name, platformName, itemId } = this.state;
        const body = {
            type: type,
            name: name,
            configs: {
                platformName: platformName,
                itemId: itemId
            }
        };
        await catTypesStore.update(name, body);
        this.props.history.push('/bots/cat-types');
    };

    render() {

        const { loader, configs, type, name, platformName, itemId } = this.state;

        return (
            <div className='main-wrapper'>
                <NavigationSide/>
                <main>
                    <NavigationTop/>

                    {loader && (<LoaderComponent/>)}

                    {!loader && (
                        <div className='category-edit'>
                            <h1>Category edit</h1>

                            <div className="form-item">
                                <label>
                                    Name
                                </label>
                                <input
                                    onChange={(e) => this.handleInputChange(e, 'name')}
                                    value={name}
                                    type="text"/>
                            </div>

                            <div className="form-item">
                                <label>
                                    Type
                                </label>
                                <input
                                    onChange={(e) => this.handleInputChange(e, 'type')}
                                    value={type}
                                    type="text"/>
                            </div>

                            <div className="form-item">
                                <label>
                                    Config itemId
                                </label>
                                <textarea
                                    onChange={(e) => this.handleInputChange(e, 'itemId')}
                                    value={itemId}
                                    />
                            </div>

                            <div className="form-item">
                                <label>
                                    Config platformName
                                </label>
                                <input
                                    onChange={(e) => this.handleInputChange(e, 'platformName')}
                                    value={platformName}
                                    type="text"/>
                            </div>

                            <div className="form-item">
                                <button
                                    onClick={this.handleSave}
                                    className="button suc">
                                    Save
                                </button>
                            </div>
                        </div>
                    )}
                </main>
            </div>
        );
    }
}

export default CategoriesAndTypesEditView;
