import styled from 'styled-components';

export const Styles = styled.div`
  width: 50%;

  textarea {
    height: 500px;
  }
`;

export const MessagesListStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 30px;
`;

export const MessagesItemStyled = styled.div`
  position: relative;
  padding-bottom: 40px;

  pre {
    word-break: break-all;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }

  .message-item-edit-button {
    position: absolute;
    bottom: 13px;
  }
`;

export const MessagesEditSectionStyled = styled.div`
  
`;

export const MessagesEditSectionWrapStyled = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const MessagesEditSectionItemStyled = styled.div`
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  padding: 3px;
  
  &.active{
    background-color: #5bc0de;
    border-color: #46b8da;
    color: #fff;
  }
`;