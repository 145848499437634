import React from "react";
import firebase from 'firebase/app';
import 'firebase/auth';
import {doSignInWithEmailAndPassword} from '../../../services/auth.service';


import userStore from '../../../stores/user.store';
import settingsStore from '../../../stores/settings.store';
import {observer} from 'mobx-react';


@observer
class LoginView extends React.Component {
    handleSubmit = (e) => {
        const {
            email,
            password,
        } = this.state;

        doSignInWithEmailAndPassword(email, password)
            .catch(error => {
                console.log('errorb', error);
            });

        e.preventDefault();
    };

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            user: true
        }
    }

    inputValueChangeHandle(e, type) {
        this.setState({
            [type]: e.target.value
        });
    }

    async componentDidMount() {
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            this.setState({user: user});
            if (!user) return;
            user.getIdToken().then(token => {
                userStore.getCurrentUser(token, user)
                    .catch(err => {

                    })
                    .then((data) => {
                        if (!data) {
                            this.setState({user: false});
                            return;
                        }
                        this.props.history.push(settingsStore.redirectRoute ? settingsStore.redirectRoute : '/dashboard')
                    });
            })
        });
    }

    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    render() {
        return (
            <main className='auth'>
                {this.state.user && (
                    <div className="logging">
                        Logging
                    </div>
                )}

                {!this.state.user && (
                    <React.Fragment>
                        <h1>Sign in</h1>
                        <form className='form'>
                            <div className="form-item">
                                <input
                                    type="email" className="email"
                                    value={this.state.email}
                                    placeholder='Email'
                                    onChange={(e) => this.inputValueChangeHandle(e, "email")}/>
                            </div>

                            <div className="form-item">
                                <input type="password" className="password"
                                       value={this.state.password}
                                       placeholder='Password'
                                       onChange={(e) => this.inputValueChangeHandle(e, "password")}/>
                            </div>
                            <div className="form-item center">
                                <button
                                    disabled={!this.state.email || !this.state.password}
                                    onClick={this.handleSubmit}
                                    className="button suc">Sign In
                                </button>
                            </div>
                        </form>
                    </React.Fragment>
                )}

            </main>
        );
    }
}

export default LoginView;
