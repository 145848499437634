import React from 'react';

const ServerInfoComponent = (props) => {
    return (
        <div className='server-info'>
            <h5>{props.title}</h5>
            <div className="server-info-bar">
                {Math.round(props.data * 100) / 100} {props.ext}
            </div>
        </div>
    );
};

export default ServerInfoComponent;