import React from 'react';
import { MessagesItemStyled } from './styled';
import { Link } from 'react-router-dom';

const MessageItem = ({ item }) => {
    return (
        <MessagesItemStyled className="panel-x">
            <h3>{ item.name }</h3>
            <pre>{ item.body }</pre>
            -----------------------------------
            <p>promoLink: {item.promoLink}</p>
            <p>promoText: {item.promoText}</p>
            <p>imageUrl: {item.imageUrl}</p>

            <div className='message-item-edit-button'>
                <Link
                    className="button suc"
                    to={ `/bots/messages-edit/${ item._id }` }>
                    Edit
                </Link>
            </div>
        </MessagesItemStyled>
    );
};

export default MessageItem;