import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

export const Styles = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  position: relative;
  background: #fff;
  border: 1px solid #e6e9ed;
  -webkit-column-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  column-break-inside: avoid;
  opacity: 1;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
`;

export const StylesDt = styled.div`
  span {
    font-weight: bold;
  }
`;

const SentAlertItemMini = ({ item }) => {
    return (
        <Styles>
            <StylesDt>
                When: <span>{moment(item.createdAt).format("HH:mm, DD-MM-YYYY")}</span>
            </StylesDt>
            <StylesDt>
                ServerId: <span>{item.serverId}</span>
            </StylesDt>
            <StylesDt>
                serverName: <span>{item.serverName}</span>
            </StylesDt>
            <StylesDt>
                alarmType: <span>{item.alarmType}</span>
            </StylesDt>
            <StylesDt>
                platformSent: <span>{item.platformSent}</span>
            </StylesDt>
            <StylesDt>
                message: <span>{item.message}</span>
            </StylesDt>
        </Styles>
    );
};

export default SentAlertItemMini;


// {
//     "_id": "617a59716743c3c6fe468f66",
//     "serverId": "6177f2c85a0e6a735590da9d",
//     "userId": "6175893772b5fd1c08540498",
//     "serverName": "Dedipath LV",
//     "severity": 30,
//     "alarmType": "server_stopped_getting_data",
//     "platformSent": "email",
//     "message": "{\"server_name\":\"Dedipath LV\",\"severity\":\"Critical\",\"alarm_message\":\"Agent stopped receiving data\"}",
//     "createdAtUnix": 1635408241454,
//     "createdAt": "2021-10-28T08:04:01.455Z",
//     "updatedAt": "2021-10-28T08:04:01.455Z",
//     "__v": 0
// }