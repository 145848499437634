import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Styles = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  position: relative;
  background: #fff;
  border: 1px solid #e6e9ed;
  -webkit-column-break-inside: avoid;
  column-break-inside: avoid;
  opacity: 1;
  transition: all .2s ease;
`;

export const StylesData = styled.div`
  span {
    font-weight: bold;
  }
`;

export const StylesNoti = styled.div`

`;

export const MoreInfoStyles = styled.div`

`;

const UserItem = ({ item }) => {
    let notiHaveData = false;
    let notiKeys = false;
    if (item.notifications) {
        notiKeys = Object.keys(item.notifications);
        notiHaveData = true;
    }

    return (
        <Styles>
            <StylesData>
                Total Servers: <span>{ item.servers ? item.servers.length : 'NA'}</span>
            </StylesData>
            <StylesData>
                Total Uptimes: <span>{ item.uptimes ? item.uptimes.length : 'NA'}</span>
            </StylesData>
            <StylesData>
                Email: <span>{ item.email }</span>
            </StylesData>
            <StylesData>
                Country: <span>{ item.country }</span>
            </StylesData>

            <StylesData>
                Subscription: <span>{ item.subscription }</span>
            </StylesData>
            <StylesData>
                Last Login: <span>{ item.lastLoginN }</span>
            </StylesData>
            <StylesData>
                { notiHaveData && (
                    <StylesNoti>
                        { notiKeys.map((ll, idxq) => {
                            const val = item.notifications[ll];
                            return (
                                <div key={ idxq + 'zd12' }>
                                    { ll } : { val.toString() }
                                </div>
                            );
                        }) }
                    </StylesNoti>
                ) }
            </StylesData>
            <MoreInfoStyles>
                <Link
                    to={ `/syagent/users/${ item._id }` }>
                    More Info
                </Link>
            </MoreInfoStyles>
        </Styles>
    );
};

export default UserItem;