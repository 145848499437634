import React, { Component } from 'react';
import NavigationSide from '../../components/navigation-side/NavigationSide';
import NavigationTop from '../../components/navigation-top/NavigationTop';
import LoaderComponent from '../../components/loader/Loader.component';
import Log from './components/Log';
import { List, WindowScroller, AutoSizer } from 'react-virtualized';
import moment from 'moment';
import botsStore from '../../../stores/bots.store';
import LogModalComponent from '../../components/modals/LogModal.component';
import { observer } from 'mobx-react';

@observer
class BotsLogsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            timeNow: Date.now(),
            page: 0,
            logs: [],
            logModalIs_Open: false,
            logIndex: 0
        };
        // this.socket = socketIOClient(appSettings.apiUrl);
        // this.socket = socketIOClient('http://localhost:3001');
    }

    rowRenderer = ({ index, isScrolling, isVisible, key, parent, style }) => {
        const item = botsStore.logsArr[index];

        if (item) {
            return (
                <Log
                    key={ key }
                    index={ index }
                    style={ style }
                    data={ item }
                    open={ this.onLogOpen }
                />

            );
        }
    };

    onLogOpen = logIndex => {
        this.setState({
            logIndex: logIndex,
            logModalIs_Open: true
        });
    };

    async componentDidMount() {
        document.title = 'Bot Logs - shemesh.info';
        await botsStore.getLogs();
        this.setState({ loader: false });
        // this.getData();
    }

    componentWillUnmount() {
        // this.socket.disconnect();
    }

    getData = () => {
        const socket = this.socket;
        socket.emit('getLogs');
        socket.on('logs', data => {
            this.setState({ loader: false, logs: data });
        });
    };

    filterResults(e) {
        const valu = e.target.value;
        if (valu.length > 2) {
            const splited = valu.split('');
            let val = '';
            splited.forEach((pp, inx) => {
                if (pp && inx !== 2) {
                    val += pp;
                } else if (inx === 2) {
                    val += ':' + pp;
                }
            });
            botsStore.filterLogs(val);
        }
    }

    render() {

        // const logs = this.state.logs;
        const { logModalIs_Open, logIndex } = this.state;

        const logs = botsStore.logsArr;

        return (
            <div className="main-wrapper">
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    { this.state.loader && (<LoaderComponent/>) }

                    { !this.state.loader && (
                        <React.Fragment>
                            <h1>Logs</h1>
                            <div className="bot-logs">
                                <div className="timeNow"><i
                                    className="far fa-clock"/>
                                    Time now :
                                    { moment(this.state.timeNow)
                                        .format('H:mm') }

                                    <div className="log-filter">
                                        <input type="text"
                                               onChange={ this.filterResults }
                                        />
                                    </div>
                                </div>


                                <WindowScroller>
                                    { ({
                                        isScrolling,
                                        onChildScroll,
                                        scrollTop,
                                        height
                                    }) => {
                                        const huj = height;
                                        return (
                                            <AutoSizer disableHeight>{
                                                ({ width, height }) => {
                                                    return (
                                                        <List
                                                            ref={ (ref) => this.theGrid = ref }
                                                            className="virtualized-grid-list"
                                                            autoHeight
                                                            height={ huj }
                                                            rowCount={ logs
                                                                ? logs.length
                                                                : 0 }
                                                            rowHeight={ height
                                                                ? height
                                                                : 20 }
                                                            rowRenderer={ this.rowRenderer }
                                                            scrollTop={ scrollTop }
                                                            width={ width }
                                                        />
                                                    );
                                                }
                                            }</AutoSizer>);
                                    } }</WindowScroller>

                                {/*{logs.map((item, idx) => {*/ }
                                {/*    return (*/ }
                                {/*        <Log*/ }
                                {/*            key={idx}*/ }
                                {/*            data={item}*/ }
                                {/*        />)*/ }
                                {/*})}*/ }

                            </div>
                        </React.Fragment>
                    ) }

                    { logModalIs_Open && (
                        <LogModalComponent
                            log={ logs[logIndex] }
                            closeEvent={ () => this.setState(
                                { logModalIs_Open: false }) }
                        />
                    ) }
                </main>
            </div>
        );
    }
}

export default BotsLogsView;
