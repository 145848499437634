import React from 'react';
import utilsService from "../../../../../../services/utils.service";

const ServerTotalInfoVertical = (props) => {
    return (
        <div className='server-total-info-vertical'>
            <h5>{props.title} ({utilsService.getPercent(props.current, props.total)}%)</h5>
            <div className="server-total-info-vertical-body">
                <div className="full">
                    <div className="part" style={{height: utilsService.getPercent(props.current, props.total) + '%'}}/>
                </div>
                <div className="measures">
                    <div className="start">{props.start}</div>
                    <div className="middle">{Math.round(props.current * 100) / 100}{props.ext}</div>
                    <div className="end">{Math.round(props.total * 100) / 100}{props.ext}</div>
                </div>
            </div>
        </div>
    );
};

export default ServerTotalInfoVertical;