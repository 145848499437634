import React from 'react';
import styled from 'styled-components';
import ServerItemMini from './ServerItemMini';

export const Styles = styled.div`

`;

export const StylesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 900px) {
    display: block;
  }
`;

const ServersList = ({ list }) => {
    return (
        <Styles>
            <h2>Total: { list.length }</h2>
            <StylesWrapper>
                { list.map((server, idx) => {
                    return (
                        <ServerItemMini key={ idx + 'as4dfs' }
                                        item={ server }/>);
                }) }
            </StylesWrapper>
        </Styles>
    );
};

export default ServersList;