import React from 'react';

class Ads extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enabled: false,
            name: '',
            link: '',
            images: ''
        };
    }

    componentDidMount() {
        const { item } = this.props;
        if (item) {
            this.setState(
                {
                    enabled: item.enabled,
                    name: item.name,
                    link: item.link,
                    images: item.images.join(',')
                }
            );
        }
    }

    handleInputChange = (e, type) => {
        this.setState({
            [type]: e.target.value
        });
    };

    handleButtonClick = (type, e) => {
        e.preventDefault();
        const oldState = this.state[type];
        this.setState({
            [type]: !oldState
        });
    };

    saveAdd = e => {
        e.preventDefault();
        const { enabled, name, link, images } = this.state;
        const data = {
            enabled: enabled,
            name: name,
            link: link
        };
        if (images) {
            data.images = images.split(',');
        }
        this.props.addAd(data);
    };

    render() {
        const { enabled, name, link, images } = this.state;
        return (
            <div className='ad-item-sngle-edit'>
                <div className="form-item">
                    <label>
                        Name
                    </label>
                    <input
                        onChange={(e) => this.handleInputChange(e, 'name')}
                        value={name}
                        type="text"/>
                </div>
                <div className="form-item">
                    <label>
                        Link
                    </label>
                    <input
                        onChange={(e) => this.handleInputChange(e, 'link')}
                        value={link}
                        type="text"/>
                </div>
                <div className="form-item">
                    <label>
                        Images
                    </label>
                    <textarea
                        onChange={(e) => this.handleInputChange(e, 'images')}
                        value={images}
                    />
                </div>
                <div className="buttons-group">
                    <button
                        onClick={(e) => this.handleButtonClick('enabled', e)}
                        className={enabled ? 'button info' : 'button def'}>Enabled
                    </button>
                    <button
                        onClick={this.saveAdd}
                        className={'button suc'}>Save or Add
                    </button>
                </div>
            </div>
        );
    }
}

export default Ads;

// name: String,
//     enabled: Boolean,
//     link: String,
//     images: [{
//     type: String,
//     lowercase: true,
//     trim: true
// }],
//     count: {
//     type: Number,
// default: 1
// }
