import React from 'react';

const ProgressBar = (props) => {
    return (
        <React.Fragment>
            <div className="progress-percent">{props.data.percent ? props.data.percent + '%' : '0%'}</div>
            <div className='progress-bar'>
                <div className="progress-bar-body"
                     style={{width: props.data.percent + '%'}}
                />
            </div>
        </React.Fragment>
    );
};

export default ProgressBar;