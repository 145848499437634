import userStore from '../stores/user.store';
import appSettings from '../constants/settings.constants';

class FetchService {
    authenticatedGet = async (url, apiKey = 'apiUrl') => {
        return new Promise((resolve, reject) => {
            const headers = {
                'AUTHORIZATION': `Bearer ${ userStore.userToken }`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };

            const request = new Request(appSettings[apiKey] + url, {
                method: 'GET',
                headers: headers
            });

            return fetch(request)
                .then((response) => {
                        resolve(response.json());
                    }
                );

        });
    };

    authenticatedPost = async (url, body, apiKey = 'apiUrl') => {
        return new Promise((resolve, reject) => {
            const headers = {
                'AUTHORIZATION': `Bearer ${ userStore.userToken }`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            const request = new Request(appSettings[apiKey] + url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            });

            return fetch(request)
                .then((response) => {
                        resolve(response.json());
                    }
                );

        });
    };

    authenticatedPut = async (url, body) => {
        return new Promise((resolve, reject) => {
            const headers = {
                'AUTHORIZATION': `Bearer ${ userStore.userToken }`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            const request = new Request(appSettings.apiUrl + url, {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify(body)
            });

            return fetch(request)
                .then((response) => {
                        resolve(response.json());
                    }
                );

        });
    };

    authenticatedDelete = async (url) => {
        return new Promise((resolve, reject) => {
            const headers = {
                'AUTHORIZATION': `Bearer ${ userStore.userToken }`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            const request = new Request(appSettings.apiUrl + url, {
                method: 'DELETE',
                headers: headers
            });

            return fetch(request)
                .then((response) => {
                        resolve(response.json());
                    }
                );

        });
    };
}

const fetchService = new FetchService();
export default fetchService;
