import React from 'react';
import styled from "styled-components";
import Media from "./Media";
import botsUploadsStore from "../../../stores/botsUpload.store";
import moment from "moment";

export const Styles = styled.div`
    
`;
const BotsUploadItem = ({item}) => {
    return (
        <Styles>
            <div className="name">{moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss')}</div>
            <div className="name"><b>{item.name}</b></div>
            <div className="name">{item.from.username}</div>
            <div className="name">{item.from.first_name}</div>
            <div className="name">{item.from.last_name}</div>
            <Media
                url={item.fileUrl}
            />
            <button
                onClick={() => botsUploadsStore.edit(item._id, "APPROVED")}
                className="button suc">Approve
            </button>
            <button
                onClick={() => botsUploadsStore.edit(item._id, "REJECTED")}
                className="button dang">Reject
            </button>
            <button
                onClick={() => botsUploadsStore.edit(item._id, "REJECTED", true)}
                className="button dang">BAN
            </button>
        </Styles>
    );
};

export default BotsUploadItem;
