import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const BotStatsHighChart = (props) => {
    const options = {
        chart: {
            type: 'line'
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                enableMouseTracking: false
            }
        },
        xAxis: {
            categories: props.data.categories
        },
        series: props.data.series
    };

    return (
        <HighchartsReact
            con
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default BotStatsHighChart;