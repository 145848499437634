import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const prodConfig = {
    apiKey: 'AIzaSyDL9u-w4_tot8LQaZIpkNxZTH4PnfQpqM0',
    authDomain: "admin-service-cba68.firebaseapp.com",
    projectId: "shemesh-info",
    databaseURL: 'https://shemesh-info.firebaseio.com/',
    storageBucket: 'gs://shemesh-info.appspot.com',
    messagingSenderId: "119931408153"
};

if (!firebase.apps.length) {
    firebase.initializeApp(prodConfig);
}

const auth = firebase.auth();
const firestore = firebase.firestore();
// const storage = firebase.storage();

export {
    auth,
    firestore
};
