import {action, observable} from "mobx";
import fetchService from "../services/fetch.service";

class MonitoringStore {
    @observable servers = [];
    @observable databases = [];
    @observable nodequery = null;

    @action.bound
    async getServers() {
        const resp = await fetchService.authenticatedGet('/huj/server/get-droplets');
        this.servers = resp.data.droplets;
    }

    @action.bound
    async getDatabases() {
        const resp = await fetchService.authenticatedGet('/huj/databases');
        this.databases = resp.data;
    }

    @action.bound
    async getServerNodeQuery() {
        const resp = await fetchService.authenticatedGet('/huj/server/get-nodequery');
        this.nodequery = resp.data[0];
    }
}

const monitoringStore = new MonitoringStore();

export default monitoringStore;
