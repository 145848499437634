import moment from 'moment';

class UtilsService {
    _oneDay = 86400000;
    _oneHour = 3600000;
    _oneMinute = 60000;
    _oneSecond = 1000;
    days = 0;
    hours = 0;
    mins = 0;
    secs = 0;

    getIcon = jobType => {
        let result = null;
        if (jobType.substr(jobType.length - 3) === 'ter') {
            result = 'twitter';
        } else {
            result = 'telegram';
        }

        return result;
    };

    deepFind = (obj, path) => {
        const paths = path.split('.');
        let current = obj;

        for (let i = 0; i < paths.length; ++i) {
            if (current[paths[i]] === undefined) {
                return undefined;
            } else {
                current = current[paths[i]];
            }
        }
        return current;
    };

    getPassedDaysFromDate = (date) => {
        const theDate = new Date(date);
        const now = Date.now();
        const diffInDays = Math.ceil((now - theDate) / 86400000);
        return diffInDays.toString();
    };

    normalizeApiData = data => {
        const tempData = {};
        for (let i = 0; i < data.length; i++) {
            tempData[data[i]['_id']] = data[i];
        }
        return tempData;
    };

    mergeApiData = (dataFromApi, localData) => {
        return Object.assign(localData, dataFromApi);
    };

    filterStoreIds = (ids, storeKeys) => {
        return ids.filter(x => !storeKeys.includes(x));
    };

    dateInterval = (value) => {
        this.calculateIntervalToShow(value);
        const days = this.days ? `${this.days} days, ` : '';
        const hours = this.hours ? `${this.hours} hours, ` : '';
        const mins = this.mins ? `${this.mins} mins` : '';
        return `${days} ${hours} ${mins}`;
    };

    calculateIntervalToShow = (value) => {
        let interval = value;
        this.days = Math.floor(interval / this._oneDay);
        interval -= this.days * this._oneDay;
        this.hours = Math.floor(interval / this._oneHour);
        interval -= this.hours * this._oneHour;
        this.mins = Math.floor(interval / this._oneMinute);
        interval -= this.mins * this._oneMinute;
        this.secs = Math.floor(interval / this._oneSecond);
    };

    calculateInterval(days, hours, minutes, seconds) {
        let result = (days * this._oneDay) + (hours * this._oneHour) +
            (minutes * this._oneMinute) + (seconds * this._oneSecond);
        return result;
    }

    getPercent = (cur, total) => {
        return parseInt(cur * 100 / total, 10);
    };

    formatBytes = (bytes, decimals) => {
        if (bytes == 0) return '0 Bytes';
        let k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    updatedNowToDate = (date) => {
        return moment(date).format('H:mm');
    };

    doMeAFavor(cont) {
        return JSON.parse(JSON.stringify(cont));
    }

}

const utilsService = new UtilsService();
export default utilsService;
