import {createPortal} from 'react-dom';
import React from 'react';


class ModalComponent extends React.Component {
    clickHandler = e => {
        if (e.target === e.currentTarget) {
            this.props.onClose(e);
        }
    };

    handleEscape = e => {
        if (e.keyCode === 27) {
            this.props.onClose(e);
        }
    };

    componentWillMount() {
        document.querySelector('body').addEventListener('keyup', this.handleEscape);
        document.querySelector('#root').className = 'modal-overlay';
    }

    componentWillUnmount() {
        document.querySelector('body').removeEventListener('keyup', this.handleEscape);
        document.querySelector('#root').className = '';
    }

    render() {
        return createPortal(
            <div className="modal" onClick={this.clickHandler}>
                {this.props.children}
            </div>,
            document.getElementById("portal")
        );
    }

}

export default ModalComponent;
