import { action, observable } from 'mobx';
import fetchService from '../services/fetch.service';

class SyagentStore {
    @observable users = [];
    @observable serversByUser = [];
    @observable servers = [];
    @observable sentAlertsByUser = [];
    @observable sentAlerts = [];
    @observable user = null;

    @action.bound
    async getUsers() {
        const users = await fetchService.authenticatedGet('/users',
            'syagentApi'
        );
        this.users = users.data;
    }

    @action.bound
    async getUser(userId) {
        const users = await fetchService.authenticatedGet(`/users/${ userId }`,
            'syagentApi'
        );
        this.user = users.data;
    }

    @action.bound
    async getServersByUser(userId) {
        const response = await fetchService.authenticatedGet(
            `/servers/${ userId }`,
            'syagentApi'
        );
        this.serversByUser = response.data;
    }

    @action.bound
    async getServers() {
        const response = await fetchService.authenticatedGet(
            `/servers`,
            'syagentApi'
        );
        this.servers = response.data;
    }

    @action.bound
    async getSentAlertsByUser(userId) {
        const response = await fetchService.authenticatedGet(
            `/sentAlerts/${ userId }`,
            'syagentApi'
        );
        this.sentAlertsByUser = response.data;
    }

    @action.bound
    async getSentAlerts() {
        const response = await fetchService.authenticatedGet(
            `/sentAlerts`,
            'syagentApi'
        );
        this.sentAlerts = response.data;
    }

    @action.bound
    async editUser(userId, data) {
        const response = await fetchService.authenticatedPost(
            `/users/${ userId }`,
            data,
            'syagentApi'
        );
        this.sentAlerts = response.data;
        await this.getUser(userId);
    }

    @action.bound
    async upgradeSubscription(userId, data) {
        const response = await fetchService.authenticatedPost(
            `/upgrade/${ userId }`,
            data,
            'syagentApi'
        );
        this.sentAlerts = response.data;
        await this.getUser(userId);
    }
}

const syagentStore = new SyagentStore();

export default syagentStore;