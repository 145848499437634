import {action, observable} from "mobx";
import appSettings from '../constants/settings.constants';

class SettingsStore {
    @observable appSettings = {};
    @observable redirectRoute = null;

    constructor() {
        this.getAppSettings();
    }

    @action.bound
    setAppSettings(key, value) {
        const localAppSettings = localStorage.getItem('appSettings');
        if (localAppSettings) {
            this.appSettings = JSON.parse(localAppSettings);
        } else {
            this.appSettings = appSettings;
        }

        this.appSettings[key] = value;
        this.appSettings = {...this.appSettings};
        localStorage.setItem('appSettings', JSON.stringify(this.appSettings));
    }

    getAppSettings() {
        const localAppSettings = localStorage.getItem('appSettings');
        if (localAppSettings) {
            this.appSettings = JSON.parse(localAppSettings);
        } else {
            this.appSettings = appSettings;
        }
    }

    setRedirectRoute(route) {
        if (route === '' || route === '/login') {
            this.redirectRoute = '/pipelines';
        } else {
            this.redirectRoute = route;
        }
    }
}

const settingsStore = new SettingsStore();

export default settingsStore;
