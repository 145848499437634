import React from "react";
import styled from "styled-components";

export const Styles = styled.div`
  img {
    width: 100%;
  }

  video {
    width: 100%;
  }
`;

const Media = ({ url }) => {
    const splited = url.split(".");
    const isImage = splited[splited.length - 1] !== "mp4";
    return (
        <Styles>
            { isImage && (
                <img src={ url } alt=""/>
            ) }
            { !isImage && (
                <video controls="true" src={ url }></video>
            ) }
        </Styles>
    );
};

export default Media;
