import React, {Component} from 'react';
import CommonWrapperComponent from "../../../components/CommonWrapper.component";
import monitoringStore from "../../../../stores/monitoring.store";
import ServerOverviewComponent from "./components/server-overview/ServerOverview.component";

class ServersView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        }
    }

    async componentDidMount() {
        document.title = "Servers - shemesh.info";
        if (monitoringStore.servers.length === 0) {
            await Promise.all([
                monitoringStore.getServerNodeQuery(),
                monitoringStore.getServers()]);
            this.setState({loader: false});
        } else {
            this.setState({loader: false});
        }
    }


    render() {
        const servers = monitoringStore.servers;
        const nq = monitoringStore.nodequery;

        return (
            <React.Fragment>
                <CommonWrapperComponent
                    title="Servers"
                    loader={this.state.loader}
                >
                    {servers.length > 0 && nq && (
                        <section className="servers">
                            {servers.map((item, idx) => {
                                return (
                                    <ServerOverviewComponent
                                        key={idx}
                                        do={item}
                                        nq={nq}
                                    />
                                )
                            })}
                        </section>
                    )}
                </CommonWrapperComponent>
            </React.Fragment>
        );
    }
}

export default ServersView;
