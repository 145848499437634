import styled from 'styled-components';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import NavigationSide from '../../../components/navigation-side/NavigationSide';
import NavigationTop from '../../../components/navigation-top/NavigationTop';
import LoaderComponent from '../../../components/loader/Loader.component';
import CommentItem from './CommentItem';
import telegramAdultStore from "../../../../stores/telegramAdult.store";

export const Styles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;;

  @media (max-width: 900px) {
    display: block;
  }
`;

@observer
class TeleACommentsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }

    async componentDidMount() {
        document.title = 'Comments - TeleA';
        await telegramAdultStore.getComments();
        this.setState({ loader: false });
    }

    render() {
        const comments = telegramAdultStore.comments;
        const total = comments.length;

        return (
            <div className="main-wrapper">
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    { this.state.loader && (<LoaderComponent/>) }

                    { !this.state.loader && (
                        <>
                            <h1>Users</h1>
                            <h3>Total: { total }</h3>
                            <Styles>
                                { comments.map(item => {
                                    return (<CommentItem key={ item._id } item={ item }/>);
                                }) }
                            </Styles>
                        </>
                    ) }
                </main>
            </div>

        );
    }
}

export default TeleACommentsView;