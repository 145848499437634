import {action, observable} from 'mobx';
import fetchService from '../services/fetch.service';
import utilsService from '../services/utils.service';
import moment from 'moment';

class BotsStore {
    @observable botsObj = {};
    @observable logsArr = [];
    @observable logsArrAll = [];
    @observable filteredJobs = [];
    @observable totalJobs = 0;
    @observable activeJobs = 0;
    @observable inactiveJobs = 0;
    @observable jobStats = [];
    @observable jobStats2 = {};
    @observable working = false;
    @observable botsUpdateTime = '';
    @observable category_filter = 0;
    @observable type_filter = 0;
    @observable status_filter = 1;

    @action.bound
    async getBots() {
        const bots = await fetchService.authenticatedGet('/huj/jobs');
        this.botsObj = utilsService.normalizeApiData(bots.data);
        this.botsUpdateTime = bots.now;
        this.calculateStatuses(bots.data);
        this.setFiltered();
    }

    @action.bound
    async getLogs() {
        const resp = await fetchService.authenticatedGet('/huj/logs');
        const normalLogs = this.normalazeDate(resp.data);
        this.logsArr = normalLogs;
        this.logsArrAll = normalLogs;
    }

    @action.bound
    jobFilterSet(type, val) {
        this[type] = val;
        this.setFiltered();
    }

    @action.bound
    async getJobStats() {
        const stats = await fetchService.authenticatedGet('/huj/stats');
        this.statsUpdateTime = stats.now;
        this.jobStats = stats.data;
    }

    @action.bound
    async getJobStatsV2(body) {
        const stats = await fetchService.authenticatedPost('/huj/statsv2', body);
        this.jobStats2 = {};
        for (let i = 0; i < stats.data.length; i++) {
            const stat = stats.data[i];
            const id = stat.id;
            if (this.jobStats2[id]) {
                this.jobStats2[id].push(stat);
            } else {
                this.jobStats2[id] = [];
                this.jobStats2[id].push(stat);
            }
        }
    }


    @action.bound
    async postEditJob(body) {
        const bots = await fetchService.authenticatedPost('/huj/jobs', body);
        this.botsObj = utilsService.normalizeApiData(bots.data);
        this.botsUpdateTime = bots.now;
        this.calculateStatuses(bots.data);
        this.setFiltered();
    }

    @action.bound
    async deleteJob(jobId) {
        const bots = await fetchService.authenticatedDelete('/huj/jobs/' + jobId);
        this.botsObj = utilsService.normalizeApiData(bots.data);
        this.botsUpdateTime = bots.now;
        this.calculateStatuses(bots.data);
        this.setFiltered();
    }

    calculateStatuses(data) {
        this.totalJobs = data.length;
        data.forEach(item => {
            if (item.isActive) {
                this.activeJobs++;
            } else {
                this.inactiveJobs++;
            }
        });
    }

    @action.bound
    setFiltered() {
        const tempArr = [];
        Object.keys(this.botsObj).forEach(key => {
            let verdict = true;
            const item = this.botsObj[key];
            // don't change, it can be str or int
            if (this.type_filter != 0 && item.jobType !== this.type_filter) {
                verdict = false;
            }

            // don't change, it can be str or int
            if (this.category_filter != 0 && item.jobCategory !== this.category_filter) {
                verdict = false;
            }

            let numericActiveness = 2;
            if (item.isActive) {
                numericActiveness = 1;
            }

            // don't change, it can be str or int
            if (numericActiveness != this.status_filter) {
                if (this.status_filter != 3) {
                    verdict = false;
                }
            }

            if (verdict) {
                tempArr.push(item);
            }
        });

        this.filteredJobs = tempArr;
    }

    normalazeDate(arr) {
        return arr.map(log => {
            log.createdAt = moment(log.createdAt).format('HH:mm , D/MM/YY');
            return log;
        });
    }

    @action.bound
    filterLogs(filter) {
        const tempResults = [];
        const listLength = this.logsArrAll.length;
        for (let i = listLength - 1; i >= 0; i--) {
            const currentItem = this.logsArrAll[i];
            const expr = RegExp(`${filter.toLowerCase()}`);
            if (expr.test(currentItem.createdAt.toLowerCase())) {
                tempResults.push(currentItem);
            }
        }

        this.logsArr = tempResults.reverse();
    }
}

const botsStore = new BotsStore();

export default botsStore;
