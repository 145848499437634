import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import NavigationSide from "../../components/navigation-side/NavigationSide";
import botsStore from '../../../stores/bots.store';
import catTypesStore from '../../../stores/cat-types.store';
import utilsService from '../../../services/utils.service';
import LoaderComponent from "../../components/loader/Loader.component";
import NavigationTop from "../../components/navigation-top/NavigationTop";
import {observer} from "mobx-react";

@observer
class BotsJobsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeNow: Date.now(),
            loader: true,
            intervalData: null,
            intervalTimer: null
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalData);
        clearInterval(this.intervalTimer);
    }

    async componentDidMount() {
        document.title = "Jobs - shemesh.info";
        if (catTypesStore.categories.length === 0) {
            await Promise.all([
                botsStore.getBots(),
                catTypesStore.getCats()
            ]);

            this.setState({loader: false});
        } else {
            await botsStore.getBots();
            this.setState({loader: false});
        }
        this.intervalData = setInterval(() => this.loadBots(), 120000);
        this.intervalTimer = setInterval(() => this.setState({timeNow: Date.now()}), 30000);
    }

    loadBots = async () => {
        this.setState({loader: true});
        await botsStore.getBots();
        this.setState({loader: false, timeNow: Date.now()});
    };

    handleSelectChange = async (e, what) => {
        botsStore.jobFilterSet(what, e.target.value);
        // this.setState({
        //     [what]: e.target.value === 'Category' || e.target.value === 'Type' ? null : e.target.value
        // });
    };

    filterCheck = job => {
        let res = false;
        if (this.state.cat === job.jobCategory && this.state.typ === job.jobType) {
            res = true;
        } else if (!this.state.cat && this.state.typ === job.jobType) {
            res = true;
        } else if (!this.state.typ && this.state.cat === job.jobCategory) {
            res = true;
        } else if (!this.state.typ && !this.state.cat) {
            res = true;
        }
        return res;
    };

    render() {
        const jobs = botsStore.filteredJobs;
        const cats = catTypesStore.categories;
        const types = catTypesStore.types;

        return (
            <div className='main-wrapper'>
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    {this.state.loader && (<LoaderComponent/>)}

                    {!this.state.loader && (

                        <React.Fragment>

                            <h1>Jobs</h1>

                            <div>
                                <Link
                                    className='button suc'
                                    to={'/bots/jobs/create-new-job'}>
                                    Create New Job
                                </Link>
                            </div>

                            <section className="jobs-panel">
                                <div className="jobs-panel-dash">
                                    <div className="timeNow"><i className="far fa-clock"/>
                                        Time now :
                                        {moment(this.state.timeNow).format('H:mm')}
                                    </div>
                                    <div className="counts">
                                        Total Jobs: {botsStore.totalJobs}
                                    </div>
                                    <div>
                                        Active Jobs:{botsStore.activeJobs}
                                    </div>
                                    <div>
                                        InActive Jobs:{botsStore.inactiveJobs}
                                    </div>
                                    <div>
                                        Data valid :{utilsService.updatedNowToDate(botsStore.botsUpdateTime)}
                                    </div>
                                </div>
                                <section className="bots-selectors form">
                                    <div className="form-item">
                                        <select
                                            value={botsStore.status_filter}
                                            onChange={(e) => this.handleSelectChange(e, 'status_filter')}
                                        >
                                            <option
                                                key='status_123'
                                                value={1}>Active
                                            </option>
                                            <option
                                                key='status_1233'
                                                value={2}>Disabled
                                            </option>
                                            <option
                                                key='status_1234'
                                                value={3}>All
                                            </option>
                                        </select>
                                    </div>
                                    <div className="form-item">
                                        <select
                                            value={botsStore.category_filter}
                                            onChange={(e) => this.handleSelectChange(e, 'category_filter')}
                                        >
                                            <option value={0}>Category</option>
                                            {cats.map((cat, idx) => {
                                                return (<option
                                                    key={cat.name + idx}
                                                    value={cat.name}>{cat.name}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-item">
                                        <select
                                            value={botsStore.type_filter}
                                            onChange={(e) => this.handleSelectChange(e, 'type_filter')}
                                        >
                                            <option value={0}>Type</option>
                                            {types.map((cat, idx) => {
                                                return (<option
                                                    key={cat.name + idx}
                                                    value={cat.name}>{cat.name}</option>)
                                            })}
                                        </select>
                                    </div>
                                </section>
                            </section>

                            <section className="jobs">
                                {jobs.map((job, idx) => {
                                    return (
                                        <div className="job panel-x" key={idx}>
                                            <span className="icon-t">
                                                {/*<i className="fab fa-{{getIcon(job.jobType)}}"/></span>*/}
                                                <i className={`fab fa-${utilsService.getIcon(job.jobType)}`}/></span>
                                            <h3>{job.jobName} ({job.totalRuns})</h3>
                                            <div className="status">
                                                <span
                                                    className={job.isActive ? 'good-color-back' : 'bad-color-back'}/>
                                            </div>

                                            <div className="nextRun">
                                                <span className="puk">Next Run:</span>
                                                {moment(job.nextScheduleRun).format('H:mm, d/M/YY')} (in <span
                                                className="huk">{utilsService.dateInterval(job.nextScheduleRun - this.state.timeNow)}</span>)
                                            </div>
                                            <div className="interval">
                                                <span
                                                    className="puk">Interval:</span> {utilsService.dateInterval(job.interval)}
                                            </div>

                                            <div className="jobType">
                                                <span className="puk">Type:</span>
                                                {job.jobType}
                                            </div>
                                            <div className="category">
                                                <span className="puk">Category:</span>
                                                {job.jobCategory}
                                            </div>
                                            <div className="actions">
                                                <Link
                                                    className='button suc'
                                                    to={'/bots/jobs/' + job._id}>
                                                    Edit
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })}
                            </section>
                        </React.Fragment>

                    )}
                </main>
            </div>
        );
    }
}

export default BotsJobsView;
