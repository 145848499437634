import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop.component';
import settingsStore from '../stores/settings.store';
import userStore from '../stores/user.store';

import '../styles/main.css';
import LoginView from './scenes/auth/Login.view';
import DashboardView from './scenes/dashboard/Dashboard.view';
import BotsJobsView from './scenes/bots/BotsJobs.view';
import BotsJobEditCreateView from './scenes/bots/BotsJobEditCreate.view';
import CategoriesAndTypesView from './scenes/bots/CategoriesAndTypes.view';
import BotsStatsView from './scenes/bots/BotsStats.view';
import BotsLogsView from './scenes/bots/BotsLogs.view';
import AliCsvView from './scenes/aliexpress/csvView';
import AliDataView from './scenes/aliexpress/data';
import ServersView from './scenes/monitoring/servers/Servers.view';
import DatabasesView from './scenes/monitoring/databases/Databases.view';
import BotStatsV2View from './scenes/bots/BotStatsV2.view';
import CategoriesAndTypesEditView from './scenes/bots/CategoriesAndTypesEdit.view';
import MessagesView from './scenes/bots/messages/Messages.view';
import MessagesEditView from './scenes/bots/messages/MessageEditOrCreate';
import UsersView from './scenes/syagent/users/Users.view';
import UserView from './scenes/syagent/users/User.view';
import SyagentServersView from './scenes/syagent/servers/SyagentServersView';
import SentAlertsView from './scenes/syagent/alerts/SentAlerts.view';
import JuiceUsersView from './scenes/juice/users/User.view';
import JuiceCommentsView from './scenes/juice/comments/Comments.view';
import TextmeUsersView from './scenes/textme/users/User.view';
import TextmeCommentsView from './scenes/textme/comments/Comments.view';
import NoshitUsersView from './scenes/noshit/users/User.view';
import NoshitCommentsView from './scenes/noshit/comments/Comments.view';
import BotsUploadsView from "./scenes/bots-uploads/BotsUploads.view";
import TelegramAdultView from "./scenes/telegram-adult/TelegramAdult.view";
import TeleACommentsView from "./scenes/telegram-adult/comments/Comments.view";
import SendMessageView from "./scenes/bots/send-message/SendMessage.view";


const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => {
        settingsStore.setRedirectRoute(rest.location.pathname);
        return (
            !userStore.logged
                ? <Redirect to='/login'/>
                : <Component {...props} />
        );
    }}/>
);


const App = () => {
    return (
        <Router>
            <ScrollToTop>
                <Switch>
                    <Redirect from="/" exact to="/bots/jobs"/>
                    <PrivateRoute path='/dashboard' component={DashboardView}/>
                    <PrivateRoute path='/bots/jobs/:id' component={BotsJobEditCreateView}/>
                    <PrivateRoute path='/bots/jobs/create-new-job' component={BotsJobEditCreateView}/>
                    <PrivateRoute path='/bots/jobs' component={BotsJobsView}/>
                    <PrivateRoute path='/bots/cat-types/:id' component={CategoriesAndTypesEditView}/>
                    <PrivateRoute path='/bots/cat-types' component={CategoriesAndTypesView}/>
                    <PrivateRoute path='/bots/stats' component={BotStatsV2View}/>
                    <PrivateRoute path='/bots/messages' component={MessagesView}/>
                    <PrivateRoute path='/bots/send-message' component={SendMessageView}/>
                    <PrivateRoute path='/bots/messages-edit/:id' component={MessagesEditView}/>
                    <PrivateRoute path='/bots/messages-edit' component={MessagesEditView}/>
                    <PrivateRoute path='/bots/statsOld' component={BotsStatsView}/>
                    <PrivateRoute path='/bots/logs' component={BotsLogsView}/>
                    <PrivateRoute path='/ali/csv' component={AliCsvView}/>
                    <PrivateRoute path='/ali/data' component={AliDataView}/>
                    <PrivateRoute path='/monitoring/servers' component={ServersView}/>
                    <PrivateRoute path='/monitoring/databases' component={DatabasesView}/>
                    <PrivateRoute path='/syagent/users/:id' component={UserView}/>
                    <PrivateRoute path='/syagent/users' component={UsersView}/>
                    <PrivateRoute path='/syagent/servers' component={SyagentServersView}/>
                    <PrivateRoute path='/syagent/sentAlerts' component={SentAlertsView}/>
                    <PrivateRoute path='/juice/users' component={JuiceUsersView}/>
                    <PrivateRoute path='/juice/comments' component={JuiceCommentsView}/>
                    <PrivateRoute path='/textme/users' component={TextmeUsersView}/>
                    <PrivateRoute path='/textme/comments' component={TextmeCommentsView}/>
                    <PrivateRoute path='/noshit/users' component={NoshitUsersView}/>
                    <PrivateRoute path='/noshit/comments' component={NoshitCommentsView}/>
                    <PrivateRoute path='/bots-upload/list' component={BotsUploadsView}/>
                    <PrivateRoute path='/telegram-adult/list' component={TelegramAdultView}/>
                    <PrivateRoute path='/telegram-adult/comments' component={TeleACommentsView}/>
                    <Route component={LoginView}/>
                </Switch>
            </ScrollToTop>
        </Router>
    );
};


export default App;
