import React from 'react';

const CpuSpinner = (props) => {
    const getSpeed = (load) => {
        let speed = 5;
        if (load > 0) {
            speed = 3;
        }
        if (load > 10) {
            speed = 2;
        }
        if (load > 35) {
            speed = 1;
        }
        if (load > 60) {
            speed = 0.7;
        }
        if (load > 90) {
            speed = 0.4;
        }
        if (load > 99) {
            speed = 0.1;
        }
        return speed;
    };

    return (
        <div className='cpu-spinner'>
            <div className="circle">
                <span className='process-procent'>{props.load}%</span>
                <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                     x="0px" y="0px"
                     viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                    <circle fill="none" stroke="#1ABB9C" strokeWidth="4" cx="50" cy="50" r="44" style={{opacity: 0.5}}/>
                    <circle fill="#1ABB9C" stroke="#1ABB9C" strokeWidth="3" cx="8" cy="54" r="6">
                        <animateTransform
                            attributeName="transform"
                            dur={getSpeed(props.load) + 's'}
                            type="rotate"
                            from="0 50 48"
                            to="360 50 52"
                            repeatCount="indefinite"/>

                    </circle>
                </svg>
            </div>
        </div>
    );
};

export default CpuSpinner;
