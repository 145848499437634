import { action, observable } from 'mobx';
import fetchService from '../services/fetch.service';

class JuiceStore {
    @observable users = [];
    @observable comments = [];

    @action.bound
    async getUsers() {
        const users = await fetchService.authenticatedGet('/huj/juice/users');
        this.users = users.data;
    }

    @action.bound
    async getComments() {
        const comments = await fetchService.authenticatedGet('/huj/juice/comments');
        this.comments = comments.data;
    }
}

const juiceStore = new JuiceStore();

export default juiceStore;