import React, {Component} from 'react';
import firebase from "firebase/app";
import 'firebase/storage'
import FileUploader from "react-firebase-file-uploader";
import NavigationSide from "../../../components/navigation-side/NavigationSide";
import NavigationTop from "../../../components/navigation-top/NavigationTop";
import LoaderComponent from "../../../components/loader/Loader.component";
import aliStore from "../../../../stores/ali.store";
import moment from "moment";
import ProgressBar from "../../../components/progress-bar";

class AliCsvView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            progress: 0,
            isUploading: false,
            fileToUpload: ''
        }
    }

    async componentDidMount() {
        document.title = "Ali CSV - shemesh.info";

        if (aliStore.csvs.length === 0) {
            await aliStore.getCsv();
            this.setState({loader: false});
        } else {
            this.setState({loader: false});
        }
    }

    handleParseClick = async (e, id) => {
        this.setState({loader: true});
        await aliStore.parseCSV(id);
        this.setState({loader: false});
    };

    handleDeleteClick = async (e, id) => {
        this.setState({loader: true});
        await aliStore.deleteCSV(id);
        this.setState({loader: false});
    };

    postToDb = async (url, name) => {
        await aliStore.postCSVs([{url: url, name: name}]);
        this.setState({
            progress: 0,
            isUploading: false,
            fileToUpload: ''
        })
    };


    handleUploadStart = () => this.setState({isUploading: true, progress: 0});
    handleProgress = progress => this.setState({progress});
    handleUploadError = error => {
        this.setState({isUploading: false});
        console.error(error);
    };
    handleUploadSuccess = filename => {
        this.setState({avatar: filename, progress: 100, isUploading: false});
        firebase
            .storage()
            .ref("csv")
            .child(filename)
            .getDownloadURL()
            .then(url => {
                this.postToDb(url, filename);
            });
    };


    render() {
        const csvs = aliStore.csvs;
        return (
            <div className='main-wrapper'>
                <NavigationSide/>
                <main>
                    <NavigationTop/>
                    {this.state.loader && (<LoaderComponent/>)}

                    {!this.state.loader && (
                        <React.Fragment>
                            <h1>Aliexpress CSV</h1>
                            <div className="ali-csv">
                                <h3>Upload CSV</h3>

                                <section className="upload-csvs">
                                    <ProgressBar data={{percent: this.state.progress}}/>

                                    <div className="file-upload form">
                                        <div className="form-item">
                                            <FileUploader
                                                name="csv"
                                                storageRef={firebase.storage().ref("csv")}
                                                onUploadStart={this.handleUploadStart}
                                                onUploadError={this.handleUploadError}
                                                onUploadSuccess={this.handleUploadSuccess}
                                                onProgress={this.handleProgress}
                                            />
                                        </div>
                                    </div>
                                </section>
                                <h3>CSVs</h3>
                                <section className="list-csv">
                                    <table className='table'>
                                        <tbody>
                                        {csvs.reverse().map((item, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{item.name}</td>
                                                    <td>{item.parsed ? 'Parsed' : 'Not Parsed'}</td>
                                                    <td>{moment(item.createdAt).format('D/MM/YY')}</td>
                                                    <td>
                                                        <button
                                                            onClick={(e) => this.handleParseClick(e, item._id)}
                                                            className="button suc" disabled={item.parsed}>Parse
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button
                                                            onClick={(e) => this.handleDeleteClick(e, item._id)}
                                                            className="button dang">Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </section>
                            </div>
                        </React.Fragment>
                    )}
                </main>
            </div>
        );
    }
}

export default AliCsvView;
