import {action, observable} from 'mobx';
import fetchService from '../services/fetch.service';

class TelegramAdultStore {
    @observable list = [];
    @observable comments = [];

    @action.bound
    async getList() {
        if (!this.list.length) {
            const list = await fetchService.authenticatedGet('/huj/telegram-adult/get');
            this.list = list.data;
        }
    }

    @action.bound
    async getComments() {
        const comments = await fetchService.authenticatedGet('/huj/telegram-adult/comments');
        this.comments = comments.data;
    }

    @action.bound
    async edit(id, action) {
        await fetchService.authenticatedPost('/huj/telegram-adult/edit', {_id: id, status: action});
        this.list = this.list.filter(item => item._id !== id);
    }
}

const telegramAdultStore = new TelegramAdultStore();

export default telegramAdultStore;
